import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { getFeed, getStrip } from "../../api/rest"
import { Strip } from "../../api/schema"
import { login, logout } from "../user/userSlice"

type FeedState = {
  isLoading: boolean
  isLoadingStrip: boolean
  error?: string
  strips: Strip[]
  deepStrip: Strip | null
}

const initialState: FeedState = {
  isLoading: false,
  isLoadingStrip: false,
  strips: [],
  deepStrip: null,
}

const discard = (state: FeedState) => {
  state.strips = []
  state.deepStrip = null
}

export const fetchStrips = createAsyncThunk(
  "feed/fetchStrips",
  (count: number) => getFeed(0, count)
)
export const fetchStrip = createAsyncThunk("feed/fetchStrip", getStrip)

const feedSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStrips.pending, (state) => {
        state.isLoading = true
        state.error = undefined
      })
      .addCase(fetchStrips.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = undefined
        state.strips = action.payload

        if (state.deepStrip) {
          // Remove deepStrip when it's loaded with the feed strips.
          const id = state.deepStrip.id
          if (action.payload.find((s) => s.id === id)) {
            state.deepStrip = null
          }
        }
      })
      .addCase(fetchStrips.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message
      })

    builder
      .addCase(fetchStrip.pending, (state) => {
        state.deepStrip = null
        state.error = undefined
        state.isLoadingStrip = true
      })
      .addCase(fetchStrip.fulfilled, (state, action) => {
        // Store strip if it wasn't already loaded with the rest of the feed gallery.
        if (state.strips.findIndex((s) => s.id === action.payload.id) === -1) {
          state.deepStrip = action.payload
        }
        state.error = undefined
        state.isLoadingStrip = false
      })
      .addCase(fetchStrip.rejected, (state, action) => {
        state.error = action.error.message
        state.isLoadingStrip = false
      })

    builder.addCase(login.fulfilled, discard)
    builder.addCase(logout.fulfilled, discard)
  },
})

export default feedSlice.reducer
