import React, { useCallback, useEffect, useRef, useState } from "react"
import { Link, Redirect } from "react-router-dom"
import { useSelector } from "react-redux"
import ReCAPTCHA from "react-google-recaptcha"

import { verifyCaptchaValue } from "../../api"
import { AppState, useAppDispatch } from "../../store"
import { register, showLogin } from "./userSlice"

import { Form } from "./Form"
import { Notice } from "../../components"

const RegisterForm = () => {
  const [data, setData] = useState({
    display_name: "",
    email: "",
    password: "",
  })
  const dispatch = useAppDispatch()
  const recaptcha = useRef<ReCAPTCHA>(null)

  const { user, lastRequestId, error, isLoading, accountStatus } = useSelector(
    (state: AppState) => state.user
  )

  useEffect(() => {
    if (error) {
      // New captcha value needed to resubmit the form
      recaptcha.current?.reset()
    }
  }, [error])

  const handleSubmit = useCallback(async () => {
    const captchaValue = recaptcha.current?.getValue()
    if (!captchaValue) {
      alert("Vul eerst de reCAPTCHA in.")
      return
    }
    const isHuman = await verifyCaptchaValue(captchaValue)
    if (isHuman) {
      dispatch(register(data))
    } else {
      alert("Ongeldige reCAPTCHA.")
    }
  }, [data, dispatch])

  if (user !== undefined) {
    return <Redirect to={"/account"} />
  }

  switch (accountStatus) {
    case "registered":
      return (
        <Notice>
          Je nieuwe account is aangemaakt. Controleer je inbox voor de
          activatielink om je emailadres te bevestigen.
        </Notice>
      )
    case "activated":
      return <Notice>Je account is geactiveerd. Je kunt nu inloggen.</Notice>
    default:
      break
  }

  return (
    <Form
      action="/"
      value={data}
      setValue={setData}
      header="Registratie"
      headerLink={
        <>
          Al een account?{" "}
          <Link
            to="/login"
            onClick={(event) => {
              event.preventDefault()
              dispatch(showLogin({ redirectAfterLogin: "/" }))
            }}
          >
            Log in
          </Link>
        </>
      }
      fields={[
        {
          name: "email",
          type: "email",
          label: "E-mail",
          placeholder: "E-mailadres",
          required: true,
          autoComplete: "username",
        },
        {
          name: "password",
          type: "password",
          placeholder: "Wachtwoord",
          required: true,
          autoComplete: "new-password",
          minLength: 8,
        },
        {
          name: "display_name",
          label: "Naam",
          placeholder: "Naam (voor reacties)",
          required: true,
          autoComplete: "given-name",
        },
      ]}
      onSubmit={handleSubmit}
      submitLabel="Maak account aan"
      lastRequestId={lastRequestId}
      error={error}
      isLoading={isLoading}
      autoComplete="on"
    >
      {process.env.REACT_APP_RECAPTCHA_SITE_KEY ? (
        <ReCAPTCHA
          ref={recaptcha}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        />
      ) : null}
    </Form>
  )
}

export default RegisterForm
