import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import {
  getArchive,
  getKeywords,
  getSection,
  getStrip,
  searchStrips,
} from "../../api/rest"
import { Book, Strip } from "../../api/schema"
import { login, logout } from "../user/userSlice"

export type Keyword = {
  value: string
  count: number
}

type ArchiveState = {
  deepStrip: Strip | null
  error?: string
  isLoadingIndex: boolean
  isLoadingSection: boolean
  isLoadingStrip: boolean
  isSearching: boolean
  sectionKeys?: number[]
  keywords?: Keyword[]
  query?: string
  searchResults?: Section
  sections: {
    [key: number]: Section
  }
}

export type Section = {
  books?: Book[]
  hasMore: boolean
  nextOffset: number
  strips: Strip[]
  stripsTotalCount: number
}

const initialState: ArchiveState = {
  deepStrip: null,
  isLoadingIndex: false,
  isLoadingSection: false,
  isLoadingStrip: false,
  isSearching: false,
  sections: {},
}

function resetState(state: ArchiveState) {
  state.sectionKeys = undefined
  state.keywords = undefined
  state.searchResults = undefined
  state.sections = {}
}

export const fetchIndex = createAsyncThunk("archive/fetchIndex", getArchive)
export const fetchKeywords = createAsyncThunk(
  "archive/fetchKeywords",
  getKeywords
)
export const fetchSection = createAsyncThunk("archive/fetchSection", getSection)
export const fetchStrip = createAsyncThunk("archive/fetchStrip", getStrip)
export const search = createAsyncThunk("archive/search", searchStrips)

const archiveSlice = createSlice({
  name: "archive",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIndex.pending, (state) => {
        state.isLoadingIndex = true
      })
      .addCase(fetchIndex.fulfilled, (state, action) => {
        state.sectionKeys = action.payload
          .map((section) => section.year)
          .toSorted((a, b) => a - b)
        state.error = undefined
        state.isLoadingIndex = false
        state.sections = Object.fromEntries(
          action.payload.map((section) => [
            section.year,
            {
              hasMore: section.count > 0,
              nextOffset: 0,
              stripsTotalCount: section.count,
              strips: [],
            },
          ])
        )
      })
      .addCase(fetchIndex.rejected, (state, action) => {
        state.error = action.error.message
        state.isLoadingIndex = false
      })

    builder
      .addCase(fetchKeywords.fulfilled, (state, action) => {
        state.keywords = action.payload
          .filter((v) => v.value && v.count)
          .sort((a, b) => b.count - a.count)
      })
      .addCase(fetchKeywords.rejected, (state) => {
        state.keywords = []
      })

    builder
      .addCase(fetchSection.pending, (state) => {
        state.isLoadingSection = true
      })
      .addCase(fetchSection.fulfilled, (state, action) => {
        const { offset, year: key } = action.meta.arg
        const { filterCount } = action.payload
        const nextOffset = offset + action.payload.strips.length

        state.sections[key] = {
          ...state.sections[key],
          stripsTotalCount: filterCount,
          hasMore: filterCount > nextOffset,
          books: (state.sections[key]?.books ?? []).concat(
            action.payload.books
          ),
          strips: (state.sections[key]?.strips ?? []).concat(
            action.payload.strips
          ),
          nextOffset,
        }
        state.error = undefined
        state.isLoadingSection = false

        if (state.deepStrip) {
          // Remove deepStrip if it's included in the loaded strips.
          const id = state.deepStrip.id
          if (action.payload.strips.findIndex((s) => s.id === id) !== -1) {
            state.deepStrip = null
          }
        }
      })
      .addCase(fetchSection.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.error = action.error.message
          state.isLoadingSection = false
        }
      })

    builder
      .addCase(fetchStrip.pending, (state) => {
        state.deepStrip = null
        state.isLoadingStrip = true
      })
      .addCase(fetchStrip.fulfilled, (state, action) => {
        state.isLoadingStrip = false
        state.error = undefined

        // Store strip unless it's already loaded with archive section
        const year = new Date(action.payload.published_on).getFullYear()
        const section = state.sections[year]
        if (
          section === undefined ||
          section.strips.findIndex((s) => s.id === action.payload.id) === -1
        ) {
          state.deepStrip = action.payload as Strip
        }
      })
      .addCase(fetchStrip.rejected, (state, action) => {
        state.isLoadingStrip = false
        state.error = action.error.message
      })

    builder
      .addCase(search.pending, (state, action) => {
        state.isSearching = true
        if (action.meta.arg.query !== state.query || !action.meta.arg.offset) {
          state.query = action.meta.arg.query
          state.searchResults = undefined
        }
      })
      .addCase(search.fulfilled, (state, action) => {
        const { filterCount, strips } = action.payload
        const { offset } = action.meta.arg
        const nextOffset = offset + strips.length
        state.searchResults = {
          ...state.searchResults,
          strips: (state.searchResults?.strips ?? []).concat(
            action.payload.strips
          ),
          stripsTotalCount: filterCount,
          hasMore: nextOffset < filterCount,
          nextOffset,
        }
        state.error = undefined
        state.isSearching = false

        if (state.deepStrip) {
          // Remove deepStrip if it's included in the loaded strips.
          const id = state.deepStrip.id
          if (action.payload.strips.findIndex((s) => s.id === id) !== -1) {
            state.deepStrip = null
          }
        }
      })
      .addCase(search.rejected, (state, action) => {
        state.isSearching = false
        state.error = action.error.message
      })

    builder.addCase(login.fulfilled, resetState)
    builder.addCase(logout.fulfilled, resetState)
  },
})

export default archiveSlice.reducer
