import React from "react"
import { Link } from "react-router-dom"
import { Book, Series } from "fs23-schema"

import classes from "./BooksSection.module.css"
import { BookCard } from "./BookCard"
import { Container } from "../../components"

function makeHeader(series: Series) {
  if (series.slug === "ype") {
    return series.title
  }
  return <Link to={`/series/${series.slug}`}>{series.title}</Link>
}

interface Props {
  books: Book[]
  header?: string
  series?: Series
  enableInfoLink?: boolean
  enableOrderButton?: boolean
}

export const BooksSection: React.FC<Props> = ({
  header,
  series,
  books,
  ...bookCardProps
}) => {
  return (
    <div className={classes.section} id={header?.toLowerCase() ?? series!.slug}>
      <Container>
        <h2>{header ?? makeHeader(series!)}</h2>
        <div className={classes.booksGallery}>
          {books.map((book) => (
            <BookCard key={book.id} book={book} {...bookCardProps} />
          ))}
        </div>
      </Container>
    </div>
  )
}
