import React from "react"

import classes from "./PageContainer.module.css"
import { PageHeader, PageHeaderProps } from "./PageHeader"
import { composeClasses } from "../util/composeClasses"

type PageContainerProps = {
  header: PageHeaderProps
}

/**
 * Combination of a `PageHeader` with a responsive `Container` component.
 */

export const PageContainer: React.FC<
  PageContainerProps & ContainerProps & React.HTMLAttributes<HTMLDivElement>
> = ({ header, ...props }) => {
  return (
    <React.Fragment>
      <PageHeader {...header} />
      <Container {...props} />
    </React.Fragment>
  )
}

type ContainerProps = {
  readableWidth?: boolean
}

/**
 * Simple container div styled with responsive layout.
 */
export const Container: React.FC<
  ContainerProps & React.HTMLAttributes<HTMLDivElement>
> = ({ className, readableWidth = false, ...props }) => (
  <div
    className={composeClasses(
      classes.container,
      readableWidth && classes.readableWidth,
      className
    )}
    {...props}
  />
)
