import { authentication, createDirectus, rest } from "@directus/sdk"
import type Schema from "fs23-schema"

const { REACT_APP_DIRECTUS_URL } = process.env

if (!REACT_APP_DIRECTUS_URL) {
  throw new Error("Missing Directus URL")
}

const client = createDirectus<Schema>(REACT_APP_DIRECTUS_URL)
  .with(authentication("cookie", { autoRefresh: true, credentials: "include" }))
  .with(rest({ credentials: "include" }))

export default client
