import moment from "moment"
import nl from "moment/locale/nl"

moment.updateLocale("nl", nl)

moment.calendarFormat = (theMoment, now) => {
  var diff = theMoment.diff(now, "days", true)
  if (diff < -6)
    return theMoment.year() === now.year() ? "sameYear" : "sameElse"
  if (diff < -1) return "lastWeek"
  if (diff < 0) return "lastDay"
  if (diff < 1) return "sameDay"
  if (diff < 2) return "nextDay"
  return "sameElse"
}

function capitalize(string) {
  return string.charAt(0).toLocaleUpperCase("nl") + string.slice(1)
}

export const formatMonth = (timestamp) => {
  return capitalize(moment(timestamp).format("MMMM y"))
}

export const formatPublishDay = (timestamp) => {
  return capitalize(
    moment(timestamp).calendar(null, {
      sameDay: "[vandaag]",
      lastDay: "[gisteren]",
      nextDay: "[morgen]",
      lastWeek: "dddd", // dinsdag
      nextWeek: "[komende] dddd", // komende dinsdag
      sameYear: "D MMMM", // 5 februari
      sameElse: "D MMM YYYY", // 5 feb. 2019
    })
  )
}

export const formatTime = (utcString) => {
  return capitalize(moment.utc(utcString).local().calendar())
}
