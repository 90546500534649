import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { getComments, postComment, postLike, deleteLike } from "../../api/rest"
import { Comment, CommentLike, User } from "../../api/schema"

type CommentsState = {
  comments: null | Comment[]
  isLoading: boolean
  isPosting: boolean
  error?: string
  postError?: string
  inflightLikeRequest?: string
}

export const fetchComments = createAsyncThunk("comments/fetch", getComments)
export const addComment = createAsyncThunk("comments/post", postComment)
export const likeComment = createAsyncThunk("comments/like", postLike)
export const unlikeComment = createAsyncThunk("comments/unlike", deleteLike)

const commentsSlice = createSlice({
  name: "comments",
  initialState: {
    comments: null,
  } as CommentsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchComments.pending, (state) => {
      state.comments = null
      state.error = undefined
      state.isLoading = true
    })
    builder.addCase(fetchComments.fulfilled, (state, action) => {
      // Sort root comments by most liked
      state.comments = action.payload.toSorted((a, b) =>
        a.reply_to === null && b.reply_to === null
          ? (b.likes?.length ?? 0) - (a.likes?.length ?? 0)
          : 0
      )
      state.error = undefined
      state.isLoading = false
    })
    builder.addCase(fetchComments.rejected, (state, action) => {
      if (action.error.name === "AbortError") {
        return
      }
      state.comments = null
      state.error = action.error.message
      state.isLoading = false
    })

    builder.addCase(addComment.pending, (state) => {
      state.isPosting = true
      state.postError = undefined
    })
    builder.addCase(addComment.fulfilled, (state, action) => {
      state.isPosting = false
      if (state.comments == null) {
        state.comments = [action.payload]
      } else {
        state.comments.push(action.payload)
      }
      state.postError = undefined
    })
    builder.addCase(addComment.rejected, (state, action) => {
      state.isPosting = false
      state.postError = action.error.message
    })

    builder.addCase(likeComment.pending, (state, action) => {
      state.inflightLikeRequest = action.meta.requestId
      pushLike(state, {
        id: NaN,
        created_on: "",
        owner: action.meta.arg.owner,
        comment: action.meta.arg.comment,
      })
    })
    builder.addCase(likeComment.fulfilled, (state, action) => {
      if (state.inflightLikeRequest !== action.meta.requestId) {
        return
      }
      // Commit like by replacing it with data from backend
      replaceLike(state, action.payload)
      state.inflightLikeRequest = undefined
    })
    builder.addCase(likeComment.rejected, (state, action) => {
      if (state.inflightLikeRequest !== action.meta.requestId) {
        return
      }
      removeLike(state, action.meta.arg.comment, action.meta.arg.owner)
      state.inflightLikeRequest = undefined
    })

    builder.addCase(unlikeComment.pending, (state, action) => {
      state.inflightLikeRequest = action.meta.requestId
      removeLike(state, action.meta.arg.comment, action.meta.arg.owner)
    })
    builder.addCase(unlikeComment.fulfilled, (state, action) => {
      if (state.inflightLikeRequest === action.meta.requestId) {
        state.inflightLikeRequest = undefined
      }
    })
    builder.addCase(unlikeComment.rejected, (state, action) => {
      if (state.inflightLikeRequest !== action.meta.requestId) {
        return
      }
      pushLike(state, action.meta.arg)
      state.inflightLikeRequest = undefined
    })
  },
})

const pushLike = (state: CommentsState, like: CommentLike) => {
  const commentIndex = state.comments!.findIndex((c) => c.id === like.comment)
  if (state.comments![commentIndex].likes === null) {
    state.comments![commentIndex].likes = [like]
  } else {
    state.comments![commentIndex].likes!.push(like)
  }
}

const replaceLike = (state: CommentsState, like: CommentLike) => {
  const commentIndex = state.comments!.findIndex((c) => c.id === like.comment)
  const likeIndex = state.comments![commentIndex].likes!.findIndex((l) => {
    const localID = typeof l.owner == "object" ? l.owner.id : l.owner
    const remoteID = typeof like.owner == "object" ? like.owner.id : like.owner
    return localID === remoteID
  })
  state.comments![commentIndex].likes![likeIndex] = like
}

const removeLike = (
  state: CommentsState,
  comment: number | Comment,
  owner: string | User
) => {
  const commentIndex = state.comments!.findIndex((c) => c.id === comment)
  if (state.comments![commentIndex].likes === null) {
    return
  }
  const ownerID = typeof owner == "object" ? owner.id : owner
  const likes = state.comments![commentIndex].likes!.filter((l) => {
    const likeOwnerID = typeof l.owner == "object" ? l.owner.id : l.owner
    return likeOwnerID !== ownerID
  })
  state.comments![commentIndex].likes = likes
}

export default commentsSlice.reducer
