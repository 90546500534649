import React, { ReactNode, useEffect } from "react"

import classes from "./InfiniteScroll.module.css"
import { Button, Spinner } from "."
import { useVisibility } from "../util/useVisibility"

type InfiniteScrollProps = {
  buttonLabel?: string
  children: ReactNode
  hasMore: boolean
  isLoading: boolean
  loading?: React.ReactNode
  loadMore: () => void
  offset?: number
}

export const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  buttonLabel = "Load more",
  children,
  hasMore,
  isLoading,
  loading = <Spinner size="small" />,
  loadMore,
  offset = 0,
}) => {
  const [isVisible, ref] = useVisibility<HTMLDivElement>({
    offset,
    throttle: 1000,
  })

  useEffect(() => {
    if (isVisible && hasMore && !isLoading) {
      loadMore()
    }
  }, [hasMore, isLoading, isVisible, loadMore])

  return (
    <div>
      <div>{children}</div>
      {isLoading ? (
        <div className={classes.button}>{loading}</div>
      ) : hasMore ? (
        <div className={classes.button} ref={ref}>
          <Button onClick={() => loadMore()}>{buttonLabel}</Button>
        </div>
      ) : null}
    </div>
  )
}
