import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Page } from "fs23-schema"
import { getPage } from "../../api"

interface PageState {
  isLoading: boolean
  error?: string
  page?: Page
}

const initialState: PageState = {
  isLoading: false,
}

export const fetchPage = createAsyncThunk("page/fetchPage", getPage)

const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPage.pending, (state) => {
        state.isLoading = true
        state.error = undefined
      })
      .addCase(fetchPage.fulfilled, (state, action) => {
        state.page = action.payload
        state.error = undefined
        state.isLoading = false
      })
      .addCase(fetchPage.rejected, (state, action) => {
        state.error = action.error.message ?? "Error"
        state.isLoading = false
      })
  },
})

export default pageSlice.reducer
