import React, { ReactNode, useState, useEffect } from "react"
import { FaRegSadCry } from "react-icons/fa"

import classes from "./EmptyMessage.module.css"

interface EmptyMessageProps {
  children?: ReactNode
  show?: boolean
}

export const EmptyMessage: React.FC<EmptyMessageProps> = ({
  show = true,
  children = "Er zijn even geen strips.",
}) => {
  return useDelayedBoolean(show) ? (
    <div className={classes.EmptyMessage}>
      <h1>
        <FaRegSadCry />
      </h1>
      <p>{children}</p>
    </div>
  ) : null
}

const useDelayedBoolean = (value: boolean, ms: number = 500) => {
  const [result, setResult] = useState(false)

  useEffect(() => {
    if (value) {
      let timer = setTimeout(() => {
        setResult(true)
      }, ms)
      return () => clearTimeout(timer)
    } else {
      setResult(false)
    }
  }, [value, ms])

  return result
}
