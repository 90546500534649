import React, { useEffect, useCallback, useState } from "react"
import { useSelector } from "react-redux"

import { fetchComments, likeComment, unlikeComment } from "./commentsSlice"
import { AppState, useAppDispatch } from "../../store"
import { CommentLike } from "../../api/schema"

import Comments from "./Comments"
import CommentForm from "./CommentForm"
import { Spinner } from "../../components"
import { useVisibility } from "../../util/useVisibility"
import { showLogin } from "../user/userSlice"

type CommentsSectionProps = {
  stripId: number
}

const CommentsSection: React.FC<CommentsSectionProps> = ({ stripId }) => {
  const [needsFetch, setNeedsFetch] = useState(false)
  const { comments, isLoading, error } = useSelector(
    (state: AppState) => state.comments
  )
  const user = useSelector((state: AppState) => state.user.user)
  const dispatch = useAppDispatch()
  const [isVisible, ref] = useVisibility<HTMLDivElement>({
    offset: 250,
    throttle: 500,
  })

  useEffect(() => {
    setNeedsFetch(true)
  }, [stripId])

  useEffect(() => {
    if (isVisible && needsFetch) {
      setNeedsFetch(false)
      dispatch(fetchComments(stripId))
    }
  }, [needsFetch, dispatch, isVisible, stripId])

  const makeForm = useCallback(
    (replyTo?: number, onClose?: () => void) => (
      <CommentForm stripId={stripId} replyTo={replyTo} onClose={onClose} />
    ),
    [stripId]
  )

  const handleLike = useCallback(
    (comment: number) => {
      if (user === undefined) {
        dispatch(showLogin())
      } else {
        dispatch(likeComment({ comment, owner: user }))
      }
    },
    [dispatch, user]
  )

  const handleUnlike = useCallback(
    (like: CommentLike) => {
      dispatch(unlikeComment(like))
    },
    [dispatch]
  )

  return (
    <div ref={ref}>
      {error ? (
        <p>{error}</p>
      ) : isLoading ? (
        <Spinner centered />
      ) : comments && !needsFetch ? (
        <Comments
          comments={comments}
          commentForm={makeForm}
          onLike={handleLike}
          onUnlike={handleUnlike}
        />
      ) : null}
    </div>
  )
}

export default CommentsSection
