import { useEffect } from "react"
import { useSelector } from "react-redux"

import { useAppDispatch } from "../../store"
import { fetchAbout, selectInfo } from "./aboutSlice"

const useInfo = () => {
  const dispatch = useAppDispatch()
  const info = useSelector(selectInfo)

  useEffect(() => {
    if (info.data === null) {
      dispatch(fetchAbout())
    }
  }, [dispatch, info.data])

  return info
}

export default useInfo
