import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"

import {
  login,
  logout,
  updateCredentials,
  Credentials,
  showReset,
  didRedirect,
} from "./userSlice"
import { AppState, useAppDispatch } from "../../store"

import { Form } from "./Form"
import { Button, InfoIcon } from "../../components"

type LoginFormProps = {
  onCancel?: () => void
}

const LoginForm: React.FC<LoginFormProps> = ({ onCancel }) => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const {
    credentials,
    error,
    isLoading,
    lastRequestId,
    user,
    loginRedirectPath,
  } = useSelector((state: AppState) => state.user)

  const handleSubmit = (data: Credentials) => {
    dispatch(login(data))
  }

  const handleClickLink = () => {
    // Call cancel to dismiss modal, if any.
    onCancel?.()
  }

  const handleClickReset = (event: React.MouseEvent) => {
    event.preventDefault()
    dispatch(showReset())
  }

  const handleClickLogout = () => {
    dispatch(logout())
  }

  useEffect(() => {
    if (user && loginRedirectPath) {
      console.log("redirect to", loginRedirectPath)
      dispatch(didRedirect())
      history.push(loginRedirectPath)
    }
  }, [dispatch, history, user, loginRedirectPath])

  if (user) {
    return (
      <div
        style={{
          backgroundColor: "var(--background-color)",
          padding: 30,
          borderRadius: 10,
        }}
      >
        <p>Hoi, {user.display_name}!</p>
        <Button onClick={handleClickLogout}>Log uit</Button>
      </div>
    )
  }

  return (
    <Form
      action="/"
      value={credentials}
      setValue={(c) => {
        dispatch(updateCredentials(c))
      }}
      header="Inloggen"
      headerLink={
        <>
          Nog geen account?{" "}
          <Link to="/register" onClick={handleClickLink}>
            Registratie
          </Link>
        </>
      }
      hint={
        <span style={{ color: "var(--alternate-tint-color)" }}>
          <InfoIcon /> Had je al een account op de oude site? Reset dan eerst
          even je wachtwoord; klik hieronder op "Wachtwoord vergeten".
        </span>
      }
      fields={[
        {
          name: "email",
          type: "email",
          label: "E-mail",
          placeholder: "E-mailadres",
          required: true,
          autoComplete: "username",
        },
        {
          name: "password",
          type: "password",
          placeholder: "Wachtwoord",
          required: true,
          autoComplete: "current-password",
        },
      ]}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      submitLabel="Log in"
      footerLink={
        <Link to="/reset-password" onClick={handleClickReset}>
          Wachtwoord vergeten
        </Link>
      }
      lastRequestId={lastRequestId}
      error={error}
      isLoading={isLoading}
      autoComplete="on"
    />
  )
}

export default LoginForm
