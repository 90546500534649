import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { addComment } from "./commentsSlice"
import { AppState, useAppDispatch } from "../../store"

import classes from "./CommentForm.module.css"
import { CommentAvatar as Avatar } from "./Comments"
import { Button } from "../../components"
import { Link } from "react-router-dom"
import { showLogin } from "../user/userSlice"

export type CommentFormProps = {
  stripId: number
  replyTo?: number
  onClose?: () => void
}

const CommentForm: React.FC<CommentFormProps> = ({
  stripId,
  replyTo,
  onClose,
}) => {
  const dispatch = useAppDispatch()
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const {
    isLoading,
    isPosting,
    postError: error,
  } = useSelector((state: AppState) => state.comments)

  // Get user's name and avatar if logged in.
  const { user } = useSelector((state: AppState) => state.user)
  const isAuthenticated = user !== undefined
  const { display_name: userName, avatar } = user ?? {}

  // Set up new comment
  const [name, setName] = useState(userName ?? "")
  const [text, setText] = useState("")

  useEffect(() => {
    setName(userName ?? "")
  }, [userName])

  useEffect(() => {
    // After successfully posting comment
    if (hasSubmitted && !isPosting && !error) {
      if (onClose) {
        onClose()
      } else {
        setName(userName ?? "")
        setText("")
      }
    }
  }, [userName, error, hasSubmitted, isPosting, onClose])

  // Handle form input and submission

  // const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setName(event.target.value)
  // }

  const handleChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value)
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (!isLoading) {
      dispatch(
        addComment({
          name,
          text,
          strip: stripId,
          reply_to: replyTo,
        })
      )
      setHasSubmitted(true)
    }
  }

  const handleLogin = (event: React.MouseEvent) => {
    event.preventDefault()
    dispatch(showLogin())
  }

  if (!isAuthenticated) {
    return (
      <p>
        <Button onClick={handleLogin}>Log in</Button> of{" "}
        <Link to="/register">maak gratis een account aan</Link> om een reactie
        te plaatsen.
      </p>
    )
  }

  const showBadge =
    user.membership &&
    typeof user.membership == "object" &&
    !user.hide_membership_badge

  const avatarElement = (
    <Avatar
      badge={showBadge ? (user.membership as any).badge : null}
      includeBadgeLink={false}
      image={typeof avatar == "string" ? null : avatar}
      name={userName ?? name ?? ""}
    />
  )

  return (
    <div className={classes.CommentForm}>
      <div className={classes.avatar}>
        {isAuthenticated ? (
          <Link to="/account" aria-label="Account">
            {avatarElement}
          </Link>
        ) : (
          avatarElement
        )}
      </div>
      <form onSubmit={handleSubmit}>
        <div className={classes.fields}>
          <div className={classes.nameBox}>
            {userName === undefined ? (
              <>
                {/* <input
                className={classes.nameField}
                type="text"
                name="name"
                placeholder="Naam"
                value={comment.name}
                onChange={handleChangeName}
                required
              /> */}
                <span className={classes.nameLoginLink}>
                  Al een account?{" "}
                  <a href="/login" onClick={handleLogin}>
                    Log in
                  </a>
                </span>
              </>
            ) : (
              <p>{userName}</p>
            )}
          </div>

          <textarea
            className={classes.textField}
            rows={3}
            name="text"
            placeholder="Reactie"
            value={text}
            onChange={handleChangeText}
            required
          />
        </div>

        {error && <p className={classes.error}>{error}</p>}

        <div className={classes.buttons}>
          <Button type="submit" disabled={isPosting}>
            Plaats
          </Button>
        </div>
      </form>
    </div>
  )
}

export default CommentForm
