import React, { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import { fetchStrip, search } from "./archiveSlice"
import { AppState, useAppDispatch } from "../../store"

import {
  Container,
  EmptyMessage,
  InfiniteScroll,
  Spinner,
} from "../../components"
import StripGallery from "../strip/StripGallery"

const ArchiveSearchResults: React.FC = () => {
  const { query, id } = useParams<{ query?: string; id?: string }>()
  const dispatch = useAppDispatch()
  const {
    deepStrip,
    query: currentQuery,
    searchResults,
    isSearching,
  } = useSelector((state: AppState) => state.archive)

  // Fetch initial batch of strips
  useEffect(() => {
    if (query && query !== currentQuery) {
      dispatch(search({ query, limit: 25, offset: 0 }))
    }
  }, [currentQuery, dispatch, query])

  // Fetch deepstrip
  useEffect(() => {
    if (
      id &&
      searchResults !== undefined &&
      searchResults.strips.findIndex((s) => s.id === +id) === -1
    ) {
      dispatch(fetchStrip(+id))
    }
  }, [dispatch, id, searchResults, searchResults?.strips])

  const handleLoadMore = () => {
    dispatch(
      search({
        query: currentQuery!,
        limit: 25,
        offset: searchResults!.nextOffset,
      })
    )
  }

  const strips = searchResults?.strips.slice() ?? []
  if (deepStrip !== null) {
    strips.push(deepStrip)
  }

  const hasMore =
    searchResults !== undefined &&
    searchResults.stripsTotalCount > searchResults.nextOffset

  return (
    <Container>
      <Helmet>
        <title>Zoekresultaten</title>
      </Helmet>
      {query === undefined ? (
        <EmptyMessage>Vul een trefwoord in en druk op enter.</EmptyMessage>
      ) : isSearching && searchResults === undefined ? (
        <Spinner centered />
      ) : (
        <InfiniteScroll
          buttonLabel="Meer resultaten"
          hasMore={hasMore}
          isLoading={isSearching}
          loadMore={handleLoadMore}
          offset={200}
        >
          <StripGallery
            strips={strips}
            path={`/archief/search/${query}`}
            emptyMessage={`Niets gevonden over "${currentQuery}"`}
          />
        </InfiniteScroll>
      )}
    </Container>
  )
}

export default ArchiveSearchResults
