import { configureStore } from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"

import about from "./features/about/aboutSlice"
import archive from "./features/archive/archiveSlice"
import banners from "./features/banners/bannersSlice"
import books from "./features/books/booksSlice"
import cart from "./features/cart/cartSlice"
import comments from "./features/comments/commentsSlice"
import feed from "./features/feed/feedSlice"
import page from "./features/page/pageSlice"
import series from "./features/series/seriesSlice"
import strip from "./features/strip/stripSlice"
import user from "./features/user/userSlice"

const store = configureStore({
  reducer: {
    about,
    archive,
    banners,
    books,
    cart,
    comments,
    feed,
    page,
    series,
    strip,
    user,
  },
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export type AppState = ReturnType<typeof store.getState>
export default store
