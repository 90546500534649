import React, { useCallback, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useSelector } from "react-redux"
import { NavLink, Redirect, Route, Switch } from "react-router-dom"

import { AppState, useAppDispatch } from "../../store"
import { User } from "../../api/schema"
import { markFeedbackAsSeen, saveAvatar, updateMe } from "./userSlice"

import classes from "./Account.module.css"
import { AvatarEditor } from "./AvatarEditor"
import {
  Alert,
  Avatar,
  Button,
  Container,
  EmptyMessage,
  FileInput,
  FormField,
  Spinner,
} from "../../components"

const Account = () => {
  const { didUpdate, error, isLoading, user } = useSelector(
    (state: AppState) => state.user
  )

  const dispatch = useAppDispatch()

  const handleSavePassword = useCallback(
    (password: string) => {
      dispatch(updateMe({ password }))
    },
    [dispatch]
  )

  const handleSaveProfile = useCallback(
    ({ display_name }: { display_name: String }) => {
      dispatch(updateMe({ display_name }))
    },
    [dispatch]
  )

  const handleToggleHideBadge = useCallback(() => {
    if (user !== undefined) {
      dispatch(updateMe({ hide_membership_badge: !user.hide_membership_badge }))
    }
  }, [dispatch, user])

  const handleSaveAvatar = useCallback(
    ({ data }: { data: FormData }) => {
      dispatch(saveAvatar({ data }))
    },
    [dispatch]
  )

  if (!isLoading && !user) {
    return <Redirect to={"/"} />
  }

  return (
    <Container>
      <Helmet>
        <title>Je Fotostrips-account</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      {!isLoading && !user ? (
        <EmptyMessage>Je bent niet ingelogd</EmptyMessage>
      ) : (
        <section className={classes.Account}>
          <Menu />
          <div className={classes.content}>
            {isLoading ? (
              <Spinner />
            ) : (
              <React.Fragment>
                {didUpdate && (
                  <Alert
                    variant="confirm"
                    onDismiss={() => dispatch(markFeedbackAsSeen())}
                  >
                    Je profiel is bijgewerkt.
                  </Alert>
                )}
                {error && (
                  <p style={{ color: "var(--error-color)" }}>{error}</p>
                )}
                <Switch>
                  <Route path="/account" exact>
                    <Profile
                      user={user}
                      onSave={handleSaveProfile}
                      onToggleHideBadge={handleToggleHideBadge}
                    />
                  </Route>
                  <Route path="/account/foto">
                    <ProfilePicture
                      user={user}
                      onSave={(data) => handleSaveAvatar({ data })}
                    />
                  </Route>
                  <Route path="/account/wachtwoord">
                    <Password email={user?.email} onSave={handleSavePassword} />
                  </Route>
                </Switch>
              </React.Fragment>
            )}
          </div>
        </section>
      )}
    </Container>
  )
}

const Menu = () => {
  const links = [
    {
      path: "/account",
      exact: true,
      name: "Profiel",
    },
    {
      path: "/account/foto",
      name: "Foto",
    },
    {
      path: "/account/wachtwoord",
      name: "Wachtwoord",
    },
  ]
  return (
    <div className={classes.menu}>
      <h1>Account</h1>
      <ol className={classes.menuItems}>
        {links.map((link) => (
          <li key={link.path}>
            <NavLink
              activeClassName={classes.active}
              to={link.path}
              exact={link.exact}
            >
              {link.name}
            </NavLink>
          </li>
        ))}
      </ol>
    </div>
  )
}

const Profile = ({
  user,
  onToggleHideBadge,
  onSave,
}: {
  user?: User
  onToggleHideBadge: () => void
  onSave: (profile: { display_name: string }) => void
}) => {
  return (
    <div>
      <h2>Profiel</h2>
      {user === undefined ? (
        <Spinner />
      ) : (
        <NameEditor
          name={user.display_name}
          onSave={(display_name) => onSave({ display_name })}
        />
      )}

      {user && user.membership ? (
        <>
          <h2>Petje.af</h2>
          <input
            type="checkbox"
            checked={user.hide_membership_badge}
            id="hideMembershipBadge"
            name="hideMembershipBadge"
            onChange={() => onToggleHideBadge()}
          />
          <label htmlFor="hideMembershipBadge">Badge verbergen</label>
        </>
      ) : null}
    </div>
  )
}

const NameEditor = ({
  name,
  onSave,
}: {
  name: string
  onSave: (name: string) => void
}) => {
  const [newName, setNewName] = useState(name)
  const [didChange, setDidChange] = useState(false)
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        onSave(newName)
      }}
    >
      <FormField
        autoComplete="given-name"
        label="Naam"
        name="display_name"
        placeholder="Naam (weergegeven bij reacties)"
        value={newName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setNewName(e.target.value)
          setDidChange(e.target.value !== name)
        }}
        required
      />
      <p>
        <small>Je voornaam voor weergave bij reacties.</small>
      </p>
      {didChange && <Button>Bewaar</Button>}
    </form>
  )
}

const ProfilePicture = ({
  user,
  onSave,
}: {
  user: User | undefined
  onSave: (data: FormData) => void
}) => {
  const [selectedImage, selectImage] = useState<File | null>(null)

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      selectImage(event.target.files[0])
    }
  }

  return (
    <div className={classes.ProfilePicture}>
      <h2>Foto</h2>
      {selectedImage && user ? (
        <AvatarEditor
          avatar={{
            src: URL.createObjectURL(selectedImage),
            srcSet: "",
            alt: user.display_name,
          }}
          onCancel={() => selectImage(null)}
          onSave={onSave}
        />
      ) : (
        <>
          {user?.avatar &&
          typeof user.avatar == "object" &&
          !user.avatar.is_avatar_approved ? (
            <Alert>Je nieuwe foto wordt nog gecontroleerd.</Alert>
          ) : null}
          <Avatar
            image={
              user?.avatar && typeof user.avatar == "object"
                ? user.avatar
                : null
            }
            name={user?.display_name ?? ""}
            large
          />
          {user && (
            <div className={classes.buttons}>
              <FileInput
                accept="image/*"
                multiple={false}
                onChange={handleFile}
              >
                Kies foto
              </FileInput>
            </div>
          )}
        </>
      )}
    </div>
  )
}

const Password = ({
  email,
  onSave,
}: {
  email?: string | null
  onSave: (password: string) => void
}) => {
  const [newPassword, setNewPassword] = useState("")
  const [newPasswordConfirmed, setNewPasswordConfirmed] = useState("")

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        if (newPassword !== newPasswordConfirmed) {
          alert(
            "Het nieuwe wachtwoord is niet twee keer hetzelfde gespeld, probeer het opnieuw."
          )
          setNewPassword("")
          setNewPasswordConfirmed("")
          return
        }
        onSave(newPassword)
      }}
    >
      <h2>Inloggegevens</h2>

      <FormField
        name="email"
        label="E-mail"
        autoComplete="off"
        defaultValue={email ?? "..."}
        disabled
        readOnly
        title="Je e-mailadres kan niet worden aangepast."
      />
      <FormField
        name="password"
        type="password"
        label="Wachtwoord"
        placeholder="Nieuw wachtwoord"
        autoComplete="new-password"
        value={newPassword}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setNewPassword(e.target.value)
        }
        required
        minLength={8}
        maxLength={32}
      />
      <FormField
        name="password-confirm"
        type="password"
        label="Wachtwoord"
        placeholder="Nieuw wachtwoord (herhalen)"
        autoComplete="new-password-confirm"
        value={newPasswordConfirmed}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setNewPasswordConfirmed(e.target.value)
        }
        required
      />
      <Button>Bewaar</Button>
    </form>
  )
}

export default Account
