import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Switch, Route } from "react-router-dom"
import { useSelector } from "react-redux"

import About from "./features/about/About"
import Account from "./features/user/Account"
import Activation from "./features/user/Activation"
import BookDetail from "./features/books/BookDetail"
import Books from "./features/books/Books"
import Cart from "./features/cart/Cart"
import Checkout from "./features/cart/Checkout"
import Feed from "./features/feed/Feed"
import LoginForm from "./features/user/LoginForm"
import OrderConfirmation from "./features/cart/OrderConfirmation"
import RegisterForm from "./features/user/RegisterForm"
import ResetForm from "./features/user/ResetForm"
import Series from "./features/series/Series"
import SeriesGallery from "./features/series/SeriesGallery"

import { useAppDispatch } from "./store"
import { refreshAuth } from "./features/user/userSlice"

import { Layout, Spinner } from "./components"
import Archive from "./features/archive/Archive"
import { BannerPreview } from "./features/banners/Banners"
import { Page } from "./features/page/Page"

const App = () => {
  const [didRefresh, setDidRefresh] = useState(false)
  const { isLoading } = useSelector((state) => state.user)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isLoading) {
      setDidRefresh(true)
    }
  }, [isLoading])

  useEffect(() => {
    const promise = dispatch(refreshAuth())
    return promise.abort
  }, [dispatch])

  if (!didRefresh) {
    // Always wait for auth refresh
    return <Spinner centered />
  }

  return (
    <Layout>
      <Helmet>
        <title>Fotostrips</title>
      </Helmet>

      <Switch>
        <Route exact path="/" component={Feed} />
        <Route path={["/strip/:id"]} component={Feed} />

        <Route
          path={[
            "/archief/search/:query/:id",
            "/archief/search/:query",
            "/archief/:year/:id",
            "/archief/:year",
            "/archief",
          ]}
          component={Archive}
        />

        <Route
          path={["/series/:slug/:id", "/series/:slug"]}
          component={Series}
        />
        <Route path="/series" component={SeriesGallery} />

        <Route path="/winkel/mandje" component={Cart} />
        <Route path="/winkel/checkout" component={Checkout} />
        <Route
          path="/winkel/confirmation/:orderNumber"
          component={OrderConfirmation}
        />
        <Route
          path={["/winkel/:slug/:id", "/winkel/:slug"]}
          component={BookDetail}
        />
        <Route exact path="/winkel" component={Books} />

        <Route path="/account" component={Account} />
        <Route path="/activate" component={Activation} />
        <Route path="/info" component={About} />
        <Route path="/login" component={LoginForm} />
        <Route path="/banners/:id" component={BannerPreview} />
        <Route path="/register" component={RegisterForm} />
        <Route path="/reset-password" component={ResetForm} />

        <Route path="/:slug" component={Page} />
      </Switch>
    </Layout>
  )
}

export default App
