import React, { ReactNode } from "react"

import { ExternalIcon } from "./icons"

type LinkProps = {
  children: ReactNode
  href: string
}

const style: React.CSSProperties = {
  display: "inline-flex",
  alignItems: "center",
  height: 44,
}

export const ExternalLink: React.FC<LinkProps> = ({ href, children }) => (
  <a style={style} href={href} target="_blank" rel="noopener noreferrer">
    <ExternalIcon
      style={{ color: "var(--tint-color)", marginRight: "0.5em" }}
    />
    <span>{children}</span>
  </a>
)
