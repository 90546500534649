import React, { ReactNode } from "react"

import type { Image as ImageModel, Series } from "../../api/schema"

import classes from "./ArchiveHeader.module.css"
import { Button, Container, Image } from "../../components"
import { thumbAsset } from "../../api/files"

interface LayoutProps {
  children: ReactNode
  image: ImageModel | null
}

const Layout: React.FC<LayoutProps> = ({ children, image }) => (
  <Container>
    <section className={classes.ArchiveHeader}>
      <div>{children}</div>
      <div className={classes.imageWrapper}>
        {image && typeof image != "string" ? (
          <Image {...image} className={classes.image} />
        ) : null}
      </div>
    </section>
  </Container>
)

type ArchiveHeaderProps = {
  series?: Series
}

const ArchiveHeader: React.FC<ArchiveHeaderProps> = ({ series }) => {
  const image =
    series?.cover_image && typeof series.cover_image != "string"
      ? thumbAsset(series.cover_image)
      : null
  return (
    <Layout image={image}>
      <h1>{series?.title ?? "Archief"}</h1>
      <p>
        {series?.description ??
          "Hier vind je (bijna) alle Ype-strips op chronologische volgorde. Je kunt ook zoeken op personen of onderwerpen! Wil je een strip gebruiken in een publicatie? Stuur dan een mailtje."}
      </p>
      <Button linkTo="/info">Meer info &amp; contact</Button>
    </Layout>
  )
}

export default ArchiveHeader
