/** 
 * Helper to concatenate classnames using various shorthands. 
 * 
 * @example
 * composeClasses("string", true && "conditional", undefined && "absent", false || "fallback")
 * // returns "string conditional fallback"
 * 
 * @returns A single string containing the class names separated by spaces.
 */
export const composeClasses = (
  ...classes: (string | undefined | null | boolean)[]
): string =>
  classes
    .slice()
    .filter((c) => c !== undefined && c !== null && c !== false && c !== true)
    .join(" ")
