import React, { FC, HTMLAttributes } from "react"

import classes from "./Footnote.module.css"
import { InfoIcon } from "./icons"

export const Footnote: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => {
  return (
    <p {...props} className={classes.footnote}>
      <InfoIcon />
      {children}
    </p>
  )
}
