import React, { ReactNode } from "react"
import { Wrapper, Button, Menu, MenuItem } from "react-aria-menubutton"

import classes from "./IconMenuButton.module.css"
import { Link } from "react-router-dom"

type IconMenuButtonProps = {
  badge?: number | boolean
  children?: ReactNode
  icon: JSX.Element
  name: string
  onSelect: (
    value: any,
    event: React.SyntheticEvent<HTMLElement, Event>
  ) => void
  path?: string
  title?: string
}

export const IconMenuButton: React.FC<IconMenuButtonProps> = ({
  badge,
  children,
  icon,
  name,
  onSelect,
  path,
  title,
}) => {
  if (!children) {
    // Simple button without toggle menu.
    return (
      <div className={classes.IconMenuButton} title={title}>
        <Link to={path!} className={classes.button}>
          {badge && (
            <span className={classes.badge}>
              {badge !== true && badge < 10 ? badge : " "}
            </span>
          )}
          <div className={classes.background}>{icon}</div>
        </Link>
      </div>
    )
  }
  return (
    <Wrapper className={classes.IconMenuButton} onSelection={onSelect}>
      <Button tag="span" className={classes.button} title={name}>
        {badge && (
          <span className={classes.badge}>
            {badge !== true && badge < 10 ? badge : " "}
          </span>
        )}
        <div className={classes.background}>{icon}</div>
      </Button>
      <Menu className={classes.menu}>
        <ul>{children}</ul>
      </Menu>
    </Wrapper>
  )
}

interface IconMenuItemProps {
  children: ReactNode
  value?: string
}

export const IconMenuItem: React.FC<IconMenuItemProps> = ({
  children,
  value,
}) => {
  return (
    <li>
      {value === undefined ? (
        <div className={classes.readOnly} aria-readonly>
          {children}
        </div>
      ) : (
        <MenuItem tag="div" className={classes.menuItem} value={value}>
          {children}
        </MenuItem>
      )}
    </li>
  )
}
