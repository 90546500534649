import React, { ReactNode } from "react"

interface NoticeProps {
  children: ReactNode
  error?: boolean
}

export const Notice: React.FC<NoticeProps> = ({ children, error = false }) => {
  return (
    <p style={{ margin: "40px auto", color: error ? "red" : "inherit" }}>
      {children}
    </p>
  )
}
