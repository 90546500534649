import React from "react"
import { useSelector } from "react-redux"
import { Link, Redirect } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import { AppState, useAppDispatch } from "../../store"
import { createOrder, updateCustomerInfo } from "./cartSlice"
import { showLogin } from "../user/userSlice"
import { Country } from "./types/Country"
import type { CustomerInfo } from "./types/CustomerInfo"

import classes from "./Checkout.module.css"
import OrderSummary from "./OrderSummary"
import { PaymentMethodsIcons } from "./PaymentMethodsIcons"
import {
  Alert,
  Button,
  Container,
  FormField,
  HeaderWithButtons,
} from "../../components"

const Checkout = () => {
  const dispatch = useAppDispatch()
  const { customer, items, isLoadingOrder, order, error } = useSelector(
    (state: AppState) => state.cart
  )
  const isLoggedIn = useSelector(
    (state: AppState) => state.user.user !== undefined
  )

  if (order?.status === "created" && order.redirect_url) {
    if (order.redirect_url.startsWith("https://www.mollie.com")) {
      // Redirect customer to checkout URL if order has been created
      window.location.assign(order.redirect_url)
    } else {
      console.warn("Unexpected checkout URL")
    }
  } else if (items.length === 0) {
    // Nothing to check out
    return <Redirect to="/winkel" />
  }

  const handleChangeCustomerInfo = (info: CustomerInfo) => {
    dispatch(updateCustomerInfo(info))
  }

  const handleLogin = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    dispatch(showLogin())
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    dispatch(createOrder({ customer, items }))
  }

  return (
    <Container className={classes.Checkout}>
      <Helmet>
        <title>Bestelling</title>
      </Helmet>
      <section className={classes.shipment}>
        <h1>Verzendgegevens</h1>
        {error ? <Alert variant="error">{error}</Alert> : null}
        <CustomerInfoForm
          id="customer-info-form"
          info={customer}
          isLoggedIn={isLoggedIn}
          onChangeCustomerInfo={handleChangeCustomerInfo}
          onLogin={handleLogin}
          onSubmit={handleSubmit}
        />
      </section>
      <section className={classes.payment}>
        <div className={classes.summary}>
          <HeaderWithButtons tag="h2" text="Je bestelling">
            <Link to="/winkel/mandje">Bewerken</Link>
          </HeaderWithButtons>
          <OrderSummary itemized />
        </div>
        <div className={classes.paymentButtonWrapper}>
          <PaymentMethodsIcons country={customer.country} />
          <Button
            variant="primary"
            form="customer-info-form"
            disabled={isLoadingOrder}
            loading={isLoadingOrder}
          >
            Betalen
          </Button>
        </div>
      </section>
    </Container>
  )
}

type CustomerInfoFormProps = {
  id?: string
  info: CustomerInfo
  isLoggedIn: boolean
  onChangeCustomerInfo: (info: CustomerInfo) => void
  onLogin: (event: React.MouseEvent<HTMLAnchorElement>) => void
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
}

const CustomerInfoForm: React.FC<CustomerInfoFormProps> = ({
  id,
  info,
  isLoggedIn,
  onChangeCustomerInfo,
  onLogin,
  onSubmit,
}) => {
  const handleEdit = (target: HTMLInputElement) => {
    const value = target.type === "checkbox" ? target.checked : target.value
    onChangeCustomerInfo({
      ...info,
      [target.name]: value,
    })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.setCustomValidity("") // reset invalid message
    handleEdit(event.target)
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) =>
    handleEdit(event.target)

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) =>
    onChangeCustomerInfo({
      ...info,
      [event.target.name]: event.target.value,
    })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // Manually trigger blur to update the note when user presses enter inside the text input to submit the form:
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
    onSubmit(event)
  }

  return (
    <form id={id} onSubmit={handleSubmit} className={classes.CustomerInfoForm}>
      {!isLoggedIn && (
        <p className={classes.loginPrompt}>
          Heb je een account?{" "}
          <Link to="/login" onClick={onLogin}>
            Log in
          </Link>{" "}
          om je gegevens aan te vullen.
        </p>
      )}

      <FormField
        name="email"
        label="E-mail"
        placeholder="E-mailadres"
        type="email"
        autoComplete="email"
        required
        defaultValue={info.email ?? ""}
        onBlur={handleBlur}
        onChange={handleChange}
      />

      <FormField
        name="firstName"
        placeholder="Voornaam"
        autoComplete="given-name"
        autoCapitalize="words"
        required
        defaultValue={info.firstName ?? ""}
        onBlur={handleBlur}
        onChange={handleChange}
        className={classes.halfWidth}
      />

      <FormField
        name="lastName"
        placeholder="Achternaam"
        autoComplete="family-name"
        autoCapitalize="words"
        required
        defaultValue={info.lastName ?? ""}
        onBlur={handleBlur}
        onChange={handleChange}
        className={classes.halfWidth}
      />

      <FormField
        name="address"
        placeholder="Adres"
        autoComplete="street-address"
        pattern=".*[0-9].*"
        onInvalid={(event) =>
          event.currentTarget.setCustomValidity(
            "Vul straatnaam en huisnummer in."
          )
        }
        autoCapitalize="words"
        required
        defaultValue={info.address ?? ""}
        onBlur={handleBlur}
        onChange={handleChange}
      />

      <FormField
        name="postalCode"
        placeholder="Postcode"
        autoComplete="postal-code"
        autoCapitalize="characters"
        required
        pattern={postalCodePattern(info.country)}
        defaultValue={info.postalCode ?? ""}
        onBlur={handleBlur}
        onChange={handleChange}
        className={classes.halfWidth}
      />

      <FormField
        name="city"
        placeholder="Plaats"
        autoComplete="address-level2"
        autoCapitalize="words"
        required
        defaultValue={info.city ?? ""}
        onBlur={handleBlur}
        onChange={handleChange}
        className={classes.halfWidth}
      />

      <FormField
        label="Land"
        name="country"
        autoComplete="country"
        type="select"
        options={[
          {
            label: "Nederland",
            value: Country.NL,
          },
          {
            label: "België",
            value: Country.BE,
          },
        ]}
        value={info.country}
        onChange={handleSelect}
      />

      <label className={classes.checkbox}>
        <input
          type="checkbox"
          name="subscribe"
          checked={info.subscribe}
          onChange={handleChange}
        />{" "}
        Stuur mij een e-mail als er een nieuw boek of ander leuk nieuwtje van
        Ype is.
      </label>

      {isLoggedIn && (
        <label>
          <input
            type="checkbox"
            name="saveInfoForUser"
            checked={info.saveInfoForUser}
            onChange={handleChange}
          />{" "}
          Bewaar mijn adres voor volgende bestellingen.
        </label>
      )}
    </form>
  )
}

const postalCodePattern = (country: Country) => {
  switch (country) {
    case Country.NL:
      return "^[ ]*[1-9]{1}[0-9]{3} ?[A-Za-z]{2}[ ]*$"
    case Country.BE:
      return "^[ ]*[1-9]{1}[0-9]{3}[ ]*$"
    default:
      return undefined
  }
}

export default Checkout
