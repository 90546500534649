import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit"

import { fetchAbout } from "../about/aboutSlice"
import {
  getShipping,
  postOrder,
  getOrderStatus,
  PaymentMethod,
  getPaymentMethods,
} from "../../api/rest"
import { Image, ShippingCosts } from "../../api/schema"
import { login, fetchMe, refreshAuth } from "../user/userSlice"

import { CartItem } from "./types/CartItem"
import { Country } from "./types/Country"
import { CustomerInfo, mergeCustomerInfoWithUser } from "./types/CustomerInfo"
import { orderWithCart } from "./types/order"
import { PendingOrder } from "./types/PendingOrder"
import { trackCartItem, trackOrder, trackRemoveItem } from "./analytics"
import { DirectusUser } from "@directus/sdk"
import Schema from "fs23-schema"

// Local storage

const KEY_CART_ITEMS = "cart-items"

const storeCart = (cartItems: CartItem[]) => {
  if (cartItems.length) {
    localStorage.setItem(KEY_CART_ITEMS, JSON.stringify(cartItems))
  } else {
    localStorage.removeItem(KEY_CART_ITEMS)
  }
}

const storedCart = () => {
  try {
    let json = localStorage.getItem(KEY_CART_ITEMS)
    if (!json) return []
    return JSON.parse(json) as CartItem[]
  } catch {
    return []
  }
}

// Cart

type CartSlice = {
  /** Cart items of a new order */
  items: CartItem[]

  /** Shipping address etc */
  customer: CustomerInfo

  /** Order created on the backend that is pending payment */
  order: PendingOrder | null

  paymentMethods: PaymentMethod[] | null

  /** Tariffs for shipping, used to preview costs in the app */
  shippingCosts: ShippingCosts[] | null

  error?: string
  isLoadingOrder: boolean
  isLoadingPaymentMethods: boolean
  isLoadingShipping: boolean
}

const initialState: CartSlice = {
  customer: {
    country: Country.NL,
    saveInfoForUser: false,
    subscribe: false,
  },
  isLoadingOrder: false,
  isLoadingPaymentMethods: false,
  isLoadingShipping: false,
  items: storedCart(),
  order: null,
  paymentMethods: null,
  shippingCosts: null,
}

export const createOrder = createAsyncThunk(
  "cart/createOrder",
  async (
    cart: { items: CartItem[]; customer: CustomerInfo },
    { rejectWithValue }
  ) => {
    try {
      return await postOrder(orderWithCart(cart))
    } catch (err: any) {
      return rejectWithValue(err.errors)
    }
  }
)
export const fetchPaymentMethods = createAsyncThunk(
  "cart/fetchPaymentMethods",
  getPaymentMethods
)
export const fetchShipping = createAsyncThunk("cart/fetchShipping", getShipping)
export const fetchStatus = createAsyncThunk("cart/fetchStatus", getOrderStatus)

type ErrorArray = {
  extensions: {
    code: string
    reason: string
  }
  message: string
}[]

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItem: (
      state,
      action: PayloadAction<{
        id: number
        slug: string
        title: string
        price: number
        vat: number
        weight: number
        thumb?: Image
      }>
    ) => {
      const index = state.items.findIndex(
        (item) => item.book === action.payload.id
      )
      if (index !== -1) {
        state.items[index].quantity += 1
        trackCartItem(state.items[index], cartAmount(state.items))
      } else {
        const { id, slug, title, price, vat, weight, thumb } = action.payload
        const cartItem: CartItem = {
          book: id,
          slug,
          title,
          price,
          vat,
          weight,
          thumb,
          notes: [""],
          quantity: 1,
        }
        state.items.push(cartItem)
        trackCartItem(cartItem, cartAmount(state.items))
      }
      storeCart(state.items)
    },
    removeItem: (state, action: PayloadAction<CartItem>) => {
      state.items = state.items.filter(
        (item) => item.book !== action.payload.book
      )
      storeCart(state.items)
      trackRemoveItem(action.payload.book.toString(), cartAmount(state.items))
    },
    updateCountry: (state, action: PayloadAction<Country>) => {
      state.customer.country = action.payload
    },
    updateCustomerInfo: (state, action: PayloadAction<CustomerInfo>) => {
      state.customer = action.payload
    },
    updateNote: (
      state,
      action: PayloadAction<{ book: number; index: number; note: string }>
    ) => {
      const { book, index, note } = action.payload
      const itemIndex = state.items.findIndex((item) => item.book === book)
      if (itemIndex !== -1) {
        state.items[itemIndex].notes[index] = note.trim()
      }
      storeCart(state.items)
    },
    updateQuantity: (state, action: PayloadAction<CartItem>) => {
      const index = state.items.findIndex(
        (item) => item.book === action.payload.book
      )
      if (index === -1) {
        if (action.payload.quantity > 0) {
          state.items.push(action.payload)
          trackCartItem(action.payload, cartAmount(state.items))
        }
      } else {
        if (action.payload.quantity > 0) {
          state.items[index].quantity = action.payload.quantity
          trackCartItem(state.items[index], cartAmount(state.items))
        } else {
          state.items.splice(index, 1)
          trackRemoveItem(
            action.payload.book.toString(),
            cartAmount(state.items)
          )
        }
      }

      // Remove extraneous notes and add missing notes
      state.items[index].notes.splice(state.items[index].quantity)
      while (state.items[index].notes.length < state.items[index].quantity) {
        state.items[index].notes.push("")
      }

      storeCart(state.items)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAbout.fulfilled, (state, action) => {
      state.shippingCosts = action.payload.shipping_costs
    })

    builder
      .addCase(fetchShipping.pending, (state, action) => {
        state.isLoadingShipping = true
        state.error = undefined
      })
      .addCase(fetchShipping.fulfilled, (state, action) => {
        state.isLoadingShipping = false
        state.shippingCosts = action.payload
      })
      .addCase(fetchShipping.rejected, (state, action) => {
        state.isLoadingShipping = false
        state.error = action.error.message
      })

    builder
      .addCase(createOrder.pending, (state, action) => {
        state.isLoadingOrder = true
        state.order = null
        state.error = undefined
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.isLoadingOrder = false
        state.order = action.payload
        state.items = []
        storeCart(state.items)
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.isLoadingOrder = false
        // payload can contain errors array
        let message = "Error"
        if (action.payload && (action.payload as any).length !== undefined) {
          let fields: string[] = []
          ;(action.payload as ErrorArray).forEach((element: any) => {
            if (element.extensions?.code === "INVALID_PAYLOAD") {
              message = "Controleer het formulier"
              fields.push(element.extensions.reason)
            }
          })
          message += ": " + fields.join("")
        }
        state.error = message
      })

    builder
      .addCase(fetchPaymentMethods.pending, (state, action) => {
        state.isLoadingPaymentMethods = true
        state.error = undefined
      })
      .addCase(fetchPaymentMethods.fulfilled, (state, action) => {
        state.isLoadingPaymentMethods = false
        state.paymentMethods = action.payload
      })
      .addCase(fetchPaymentMethods.rejected, (state, action) => {
        state.isLoadingPaymentMethods = false
        state.error = action.error.message
      })

    builder
      .addCase(fetchStatus.pending, (state, action) => {
        state.isLoadingOrder = true
        state.error = undefined
      })
      .addCase(fetchStatus.fulfilled, (state, action) => {
        state.isLoadingOrder = false
        state.order = {
          ...state.order,
          ...action.payload,
        }
        if (
          action.payload.status === "paid" ||
          action.payload.status === "authorized"
        ) {
          trackOrder(action.payload)
        }
      })
      .addCase(fetchStatus.rejected, (state, action) => {
        state.isLoadingOrder = false
        state.error = action.error.message
      })

    builder.addCase(login.fulfilled, (state, action) => {
      state.customer = mergeCustomerInfoWithUser(
        state.customer,
        action.payload as DirectusUser<Schema>
      )
    })

    builder.addCase(fetchMe.fulfilled, (state, action) => {
      state.customer = mergeCustomerInfoWithUser(
        state.customer,
        action.payload as DirectusUser<Schema>
      )
    })

    builder.addCase(refreshAuth.fulfilled, (state, action) => {
      if (action.payload === undefined) {
        // refreshAuth may return without a result.
        return
      }
      state.customer = mergeCustomerInfoWithUser(
        state.customer,
        action.payload as DirectusUser<Schema>
      )
    })
  },
})

const cartAmount = (items: CartItem[]): number => {
  return (
    Math.round(
      items.reduce((amount, item) => amount + item.quantity * item.price, 0.0) *
        100
    ) / 100
  )
}

export const {
  addItem,
  removeItem,
  updateCountry,
  updateCustomerInfo,
  updateNote,
  updateQuantity,
} = cartSlice.actions
export default cartSlice.reducer
