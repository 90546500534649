import React from "react"
import { Link } from "react-router-dom"

import { Book } from "../../api/schema"

import baseClasses from "./Banner.module.css"
import { BookThumb } from "../BookThumb"
import classes from "./BookBanner.module.css"
import { composeClasses } from "../../util/composeClasses"

interface BookBannerProps extends React.HTMLProps<HTMLDivElement> {
  book: Book
  header: string
}

export const BookBanner: React.FC<BookBannerProps> = ({
  book,
  className,
  header,
  ...props
}) => {
  const path = `/winkel/${book.slug}`
  return (
    <div
      className={composeClasses(baseClasses.banner, classes.banner, className)}
      {...props}
    >
      <Link to={path} className={classes.cover}>
        <BookThumb book={book} />
      </Link>
      <div className={classes.content}>
        <small>{header}</small>
        <h2>
          <Link to={path}>{book.title}</Link>
        </h2>
      </div>
    </div>
  )
}
