import React, { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { useParams, useHistory } from "react-router-dom"

import { Strip } from "../../api/schema"
import { useAppDispatch } from "../../store"
import {
  open,
  close,
  currentStrip,
  selectPreviousStrip,
  selectNextStrip,
} from "./stripSlice"

import classes from "./StripModal.module.css"
import StripPage from "../../components/StripPage"
import { composeClasses } from "../../util/composeClasses"
import { Footer } from "../../components"

type StripModalProps = {
  /** Called when navigating backward beyond first strip. */
  onPrevious?: () => void

  /** Called when navigating forward beyond last strip. */
  onNext?: () => void

  /** Base path at which to open a strip. */
  path: string

  /** Strips to browse through using arrow button controls. */
  strips: Strip[]
}

const StripModal: React.FC<StripModalProps> = ({
  onPrevious,
  onNext,
  path,
  strips,
}) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { id } = useParams<{ id?: string }>()

  const strip = useSelector(currentStrip)
  const previousStrip = useSelector(selectPreviousStrip)
  const nextStrip = useSelector(selectNextStrip)

  const handlePrevious = useMemo(() => {
    return previousStrip
      ? () => history.replace(`${path}/${previousStrip.id}`)
      : onPrevious
  }, [history, onPrevious, path, previousStrip])

  const handleNext = useMemo(() => {
    return nextStrip ? () => history.replace(`${path}/${nextStrip.id}`) : onNext
  }, [history, nextStrip, onNext, path])

  // Opening or closing the modal.
  useEffect(() => {
    if (id !== undefined) {
      dispatch(open({ strips, id }))
    } else {
      dispatch(close())
    }
  }, [dispatch, strips, id])

  // Reset scroll when presenting (another) strip.
  useEffect(() => {
    if (strip) {
      window.scrollTo({ top: 0 })
    }
  }, [strip])

  const handleClickBackdrop = () => {
    history.replace(path)
  }

  return (
    <div
      className={composeClasses(
        classes.StripModal,
        strip ? classes.opened : classes.closed
      )}
    >
      <div
        className={classes.backdrop}
        onClick={strip ? handleClickBackdrop : undefined}
      />
      {strip && (
        <div className={classes.contents}>
          <main>
            <StripPage
              strip={strip}
              onPrevious={handlePrevious}
              onNext={handleNext}
              jumpsBack={onPrevious !== undefined && !previousStrip}
              jumpsForward={onNext !== undefined && !nextStrip}
            />
          </main>
          <Footer />
        </div>
      )}
    </div>
  )
}

export default StripModal
