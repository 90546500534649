import React, { ReactNode } from "react"

import { Footer, NavigationMenu } from "."

interface LayoutProps {
  children: ReactNode
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="App">
      <nav className="main">
        <NavigationMenu />
      </nav>
      <main>{children}</main>
      <Footer />
    </div>
  )
}
