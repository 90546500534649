import React, { useMemo } from "react"
import { useSelector } from "react-redux"

import { currentStrip } from "./stripSlice"
import StripModal from "./StripModal"
import type { Strip, Book } from "../../api/schema"
import { Gallery } from "../../components"

type StripGalleryProps = {
  books?: Book[]
  emptyMessage?: string
  sortingByNewest?: boolean
  onNext?: () => void
  onPrevious?: () => void
  path: string
  strips: Strip[]
}

const StripGallery = ({
  books,
  emptyMessage,
  sortingByNewest = false,
  onNext,
  onPrevious,
  path,
  strips,
}: StripGalleryProps) => {
  const [items, stripsSorted] = useMemo(() => {
    const sortedStrips = [...strips].sort(sortingByNewest ? pubDesc : pubAsc)
    if (books !== undefined) {
      return [mergeAndSort(strips, books, sortingByNewest), sortedStrips]
    } else {
      return [sortedStrips, sortedStrips]
    }
  }, [books, strips, sortingByNewest])

  const isStripOpen = useSelector(currentStrip) !== undefined

  return (
    <React.Fragment>
      <Gallery
        emptyMessage={emptyMessage}
        items={items}
        open={isStripOpen}
        path={path}
      />
      <StripModal
        onPrevious={onPrevious}
        onNext={onNext}
        path={path}
        strips={stripsSorted}
      />
    </React.Fragment>
  )
}

interface Published {
  published_on: string
  published_at?: string
}

const mergeAndSort = <A extends Published, B extends Published>(
  a: A[],
  b: B[],
  sortingByNewest: boolean
): (A | B)[] => {
  return [...a, ...b].sort(sortingByNewest ? pubDesc : pubAsc)
}

const pubAsc = <Item extends Published>(lhs: Item, rhs: Item): number => {
  const timestamp = (item: Item) => {
    return item.published_at
      ? `${item.published_on} ${item.published_at}`
      : item.published_on
  }
  const l = timestamp(lhs)
  const r = timestamp(rhs)
  if (l > r) {
    return 1
  }
  if (l < r) {
    return -1
  }
  return 0
}

const pubDesc = <Item extends Published>(lhs: Item, rhs: Item) => {
  return pubAsc(lhs, rhs) * -1
}

export default StripGallery
