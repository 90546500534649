import {
  passwordRequest,
  passwordReset,
  readMe,
  updateMe as directus_updateMe,
  uploadFiles,
} from "@directus/sdk"

import client from "./client"

export const updateMe = async (user: any) => {
  try {
    return await client.request(
      directus_updateMe(user, {
        fields: ["*", { avatar: ["*"] }, { membership: ["badge"] }],
      })
    )
  } catch (error: any) {
    if (error.errors !== undefined && error.errors.length) {
      if (error.errors[0].message === "Username is taken") {
        throw new Error(
          `Gebruikersnaam ${user.display_name} is niet beschikbaar.`
        )
      }
      throw new Error(error.errors[0].message)
    }
    throw new Error(error)
  }
}

export const saveAvatar = async ({ data }: { data: FormData }) => {
  const file = await client.request(uploadFiles(data))
  await client.request(directus_updateMe({ avatar: file.id }))
  return file
}

export async function getMe() {
  return await client.request(
    readMe({ fields: ["*", { avatar: ["*"] }, { membership: ["badge"] }] })
  )
}

type NewUserPayload = {
  email: string
  password: string
  display_name: string
}

export async function register(user: NewUserPayload) {
  const url = new URL("/account", client.url)
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      ...user,
      activation_url: new URL(
        process.env.PUBLIC_URL + "/activate",
        process.env.REACT_APP_PUBLIC_URL
      ).toString(),
    }),
    headers: { "Content-Type": "application/json" },
  })
  if (!response.ok) {
    const body = await response.json()
    throw new Error(body.error ?? response.statusText)
  }
}

export async function activate(token: string) {
  const url = new URL("/account/activate", client.url)
  const response = await fetch(url, {
    body: JSON.stringify({ token }),
    headers: { "Content-Type": "application/json" },
    method: "POST",
  })
  const result = await response.json()
  if (!response.ok) {
    throw new Error(result.error ?? response.statusText)
  }
  return result
}

export const requestPasswordReset = async ({
  email,
  resetUrl,
}: {
  email: string
  resetUrl?: string
}) => {
  await client.request(passwordRequest(email, resetUrl))
}

export const resetPassword = async ({
  password,
  token,
}: {
  password: string
  token: string
}) => {
  await client.request(passwordReset(token, password))
}

interface CaptchaResponse {
  success: boolean
  challenge_ts?: string
  hostname?: string
  "error-codes"?: string[]
}

export const verifyCaptchaValue = async (captchaValue: string) => {
  const response = await fetch(new URL("/account/verify-captcha", client.url), {
    method: "POST",
    body: JSON.stringify({ captchaValue }),
    headers: {
      "Content-Type": "application/json",
    },
  })
  if (!response.ok) {
    throw new Error("Failed to verify captcha")
  }
  const data = (await response.json()) as CaptchaResponse
  return data.success
}
