import React, { useCallback, useEffect } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import { fetchSection, fetchStrip } from "./archiveSlice"
import { AppState, useAppDispatch } from "../../store"

import { Container, InfiniteScroll, Spinner } from "../../components"
import StripGallery from "../strip/StripGallery"
import Banners from "../banners/Banners"

type ArchiveSectionProps = {
  onNext?: () => void
  onPrevious?: () => void
  year: number
}

const ArchiveSection: React.FC<ArchiveSectionProps> = ({
  onNext,
  onPrevious,
  year,
}) => {
  const { id } = useParams<{ id?: string }>()

  const dispatch = useAppDispatch()

  const { deepStrip, isLoadingSection, isLoadingStrip } = useSelector(
    (state: AppState) => state.archive
  )

  const section = useSelector((state: AppState) => state.archive.sections[year])

  // Fetch strip outside of loaded feed.
  useEffect(() => {
    if (id === undefined) {
      return
    }
    const intID = parseInt(id)
    if (
      !isLoadingStrip &&
      deepStrip?.id !== intID &&
      section?.strips?.findIndex((s) => s.id === intID) === -1
    ) {
      dispatch(fetchStrip(intID))
    }
  }, [deepStrip, dispatch, id, isLoadingStrip, section])

  const handleLoadMore = useCallback(() => {
    dispatch(
      fetchSection({ year, limit: 50, offset: section?.nextOffset ?? 0 })
    )
  }, [dispatch, section?.nextOffset, year])

  const isLoading = isLoadingSection || isLoadingStrip

  const hasMore = section
    ? section.stripsTotalCount > section.nextOffset
    : false

  const strips = (section?.strips ?? []).concat(deepStrip ? [deepStrip] : [])

  let books = section?.books ?? []

  if (strips.length > 0 && books.length > 0 && hasMore) {
    // Only show the books between the first and last strip (i.e. clamp by publish date)
    const stripDates = strips.map((strip) =>
      new Date(strip.published_on).getTime()
    )
    const minDate = new Date(0)
    minDate.setUTCMilliseconds(Math.min(...stripDates))
    const maxDate = new Date(0)
    maxDate.setUTCMilliseconds(Math.max(...stripDates))
    books = books.filter((book) => {
      const pubDate = new Date(book.published_on)
      return pubDate >= minDate && pubDate <= maxDate
    })
  }

  return (
    <Container>
      <Helmet>
        <title>{`Archief ${year}`}</title>
      </Helmet>
      {isLoading && section === undefined ? (
        <Spinner centered />
      ) : (
        <>
          <InfiniteScroll
            buttonLabel="Toon meer strips"
            hasMore={hasMore}
            isLoading={isLoading}
            loadMore={handleLoadMore}
            offset={200}
          >
            <StripGallery
              strips={strips}
              books={books}
              path={`/archief/${year}`}
              onPrevious={onPrevious}
              onNext={onNext}
            />
          </InfiniteScroll>
          {!hasMore && (section?.strips.length ?? 0) >= 50 && <CTA />}
        </>
      )}
    </Container>
  )
}

const CTA = () => {
  // const { data } = useInfo()
  const hasMembership = useSelector(
    (state: AppState) => !!state.user.user?.membership
  )

  if (hasMembership) {
    return null
  }

  return (
    <div style={{ marginTop: 20 }}>
      <Banners id={2} />
    </div>
  )
}

export default ArchiveSection
