import React from "react"

import classes from "./Spinner.module.css"

const SIZE_LARGE = "large"
const SIZE_SMALL = "small"

const sizeClasses: { [key: string]: string } = {
  [SIZE_LARGE]: classes.large,
  [SIZE_SMALL]: classes.small,
}

interface SpinnerProps {
  centered?: boolean
  color?: string
  size?: "large" | "small"
}

export const Spinner: React.FC<SpinnerProps> = ({
  size = SIZE_LARGE,
  centered = false,
  color,
}) => {
  const spinner = (
    <div
      className={`${classes.Spinner} ${sizeClasses[size]}`}
      style={
        color
          ? { borderColor: color, borderRightColor: "transparent" }
          : undefined
      }
    />
  )
  return centered ? <div className={classes.container}>{spinner}</div> : spinner
}
