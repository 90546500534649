import React, { ReactNode, useEffect } from "react"
import {
  FaCheckCircle,
  FaExclamationTriangle,
  FaInfoCircle,
} from "react-icons/fa"

import classes from "./Alert.module.css"
import { composeClasses } from "../util/composeClasses"

type Props = {
  autoDismiss?: boolean
  children: ReactNode
  onDismiss?: () => void
  variant?: string
}

export const Alert: React.FC<Props> = ({
  autoDismiss = true,
  children,
  onDismiss,
  variant = "info",
}) => {
  useEffect(() => {
    if (autoDismiss) {
      return () => {
        onDismiss?.()
      }
    }
  }, [autoDismiss, onDismiss])

  let variantClass: string
  let icon: JSX.Element

  switch (variant) {
    case "confirm":
      variantClass = classes.confirm
      icon = <FaCheckCircle />
      break
    case "error":
      variantClass = classes.error
      icon = <FaExclamationTriangle />
      break
    case "info":
    default:
      variantClass = classes.info
      icon = <FaInfoCircle />
      break
  }

  return (
    <div>
      <div
        className={composeClasses(
          classes.Alert,
          variantClass,
          onDismiss !== undefined ? classes.pointer : classes.defaultCursor
        )}
        role="alert"
        onClick={onDismiss}
      >
        {icon}
        {children}
      </div>
    </div>
  )
}
