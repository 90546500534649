import React, { useState, useEffect, ReactNode } from "react"

import classes from "./Form.module.css"
import { Button, Spinner } from "../../components"
import { FormField, FormFieldProps } from "../../components/FormField"
import { HeaderWithButtons } from "../../components/HeaderWithButtons"
import { composeClasses } from "../../util/composeClasses"

type FormProps<Value> = {
  value: Value
  setValue: (value: Value) => void
  fields: FormFieldProps[]
  lastRequestId?: string
  error?: string
  isLoading: boolean
  header: string
  headerLink?: JSX.Element
  hint?: string | JSX.Element
  finePrint?: string | JSX.Element
  footerLink?: JSX.Element
  onCancel?: () => void
  onSubmit: (data: Value) => void
  submitLabel?: string
  cancelLabel?: string
  autoComplete?: string
  action?: string
  children?: ReactNode
}

export const Form = <Value extends { [key: string]: any }>({
  value,
  setValue,
  fields = [],
  lastRequestId,
  error,
  isLoading,
  header,
  headerLink,
  hint,
  finePrint,
  footerLink,
  onCancel,
  onSubmit,
  submitLabel,
  cancelLabel,
  autoComplete,
  action,
  children,
}: FormProps<Value>) => {
  const [isErrorHidden, setIsErrorHidden] = useState(false)

  // Unhide error box with each new request/error
  useEffect(() => {
    setIsErrorHidden(false)
  }, [lastRequestId, error])

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setValue({
      ...value,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onSubmit(value)
  }

  return (
    <div className={composeClasses(classes.Form, isLoading && classes.loading)}>
      <div className={classes.box}>
        <div className={classes.formSection}>
          <HeaderWithButtons text={header}>{headerLink}</HeaderWithButtons>

          {hint && <p className={classes.hint}>{hint}</p>}

          <form
            action={action}
            method="POST"
            onSubmit={handleSubmit}
            autoComplete={autoComplete}
          >
            {fields.map((config) => (
              <FormField
                {...config}
                key={config.name}
                disabled={isLoading}
                value={value[config.name]}
                onChange={handleChange}
              />
            ))}

            {children}

            <div className={classes.buttons}>
              {onCancel !== undefined && (
                <Button variant="secondary" type="button" onClick={onCancel}>
                  {cancelLabel ?? "Annuleer"}
                </Button>
              )}

              <Button variant="primary" type="submit" disabled={isLoading}>
                {submitLabel ?? "Verstuur"}
              </Button>

              {isLoading && (
                <Spinner size="small" color="var(--alternate-tint-color)" />
              )}

              {footerLink && (
                <div className={classes.footerLink}>{footerLink}</div>
              )}
            </div>
          </form>
        </div>

        <ErrorBox hidden={isErrorHidden} onHide={() => setIsErrorHidden(true)}>
          {error}
        </ErrorBox>
      </div>

      {finePrint && <p className={classes.finePrint}>{finePrint}</p>}
    </div>
  )
}

interface ErrorBoxProps {
  children: ReactNode
  hidden?: boolean
  onHide: () => void
}

const ErrorBox: React.FC<ErrorBoxProps> = ({
  children,
  hidden = false,
  onHide,
}) => {
  const className = composeClasses(
    classes.errorBox,
    children ? classes.hasError : classes.noError,
    hidden && classes.errorHidden
  )

  return (
    <div className={className} onClick={() => onHide()}>
      <p>{children}</p>
    </div>
  )
}
