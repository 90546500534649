import React, { ReactNode } from "react"

import classes from "./ImageWithOverlay.module.css"

interface Props {
  children: ReactNode
  image: ReactNode
}

export const ImageWithOverlay: React.FC<Props> = ({ children, image }) => (
  <div className={classes.ImageWithOverlay}>
    {image}
    <div className={classes.caption}>{children}</div>
  </div>
)
