import React from "react"

import { Strip, Book } from "../api/schema"

import classes from "./Gallery.module.css"
import { ThumbLink } from "."
import { BookCard } from "../features/books/BookCard"
import { EmptyMessage } from "./EmptyMessage"

type GalleryProps = {
  emptyMessage?: string
  items: (Strip | Book)[]
  open?: boolean
  path: string
}

export const Gallery = ({
  emptyMessage,
  items,
  open = false,
  path = "/strip",
}: GalleryProps) =>
  items.length === 0 ? (
    <EmptyMessage>{emptyMessage ?? "Nothing to see here."}</EmptyMessage>
  ) : (
    <div className={[classes.Gallery, open && classes.stripOpen].join(" ")}>
      {items.map((item) =>
        (item as Book).slug !== undefined
          ? bookItem(item as Book)
          : stripItem(item as Strip, path)
      )}
    </div>
  )

const stripItem = (strip: Strip, path: string) => (
  <ThumbLink key={strip.id} strip={strip} path={path} />
)

const bookItem = (book: Book) => (
  <div key={book.slug} className={classes.fullRow + " " + classes.book}>
    <BookCard book={book} enableOrderButton enableShopLink />
  </div>
)
