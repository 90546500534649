import client from "./client"
import { getMe } from "./user"

export async function login({
  email,
  password,
}: {
  email: string
  password: string
}) {
  try {
    await client.login(email, password)
  } catch (error: any) {
    if (
      error.errors &&
      error.errors.length &&
      error.errors[0].extensions.code === "INVALID_CREDENTIALS"
    ) {
      throw new Error("Ongeldig e-mailadres en/of wachtwoord.")
    }
    throw new Error("Error")
  }
  const user = await getMe()
  localStorage.setItem("refresh", "true")
  return user
}

export const logout = async () => {
  localStorage.removeItem("refresh")
  await client.logout()
}

export async function refreshAuth() {
  if (localStorage.getItem("refresh") === null) {
    return
  }
  try {
    await client.refresh()
    return await getMe()
  } catch {
    localStorage.removeItem("refresh")
  }
}
