import React from "react"

import { Book } from "../api/schema"

import classes from "./BookThumb.module.css"
import { Image } from "./Image"
import placeholderImage from "./placeholder-book.png"
import placeholderImage2 from "./placeholder-book@2x.png"
import { thumbAsset } from "../api/files"

export const BookThumb = ({ book }: { book: Book }) => {
  const thumb =
    book.cover_image && typeof book.cover_image == "object"
      ? thumbAsset(book.cover_image, book.title)
      : bookPlaceholder(book.title)
  return <Image className={classes.BookThumb} {...thumb} />
}

export const bookPlaceholder = (title: string) => ({
  alt: title,
  src: placeholderImage,
  srcSet: `${placeholderImage}, ${placeholderImage2} 2x`,
  width: 170,
  height: 140,
})
