import React, { ButtonHTMLAttributes } from "react"
import { Link } from "react-router-dom"

import classes from "./Button.module.css"
import { composeClasses } from "../util/composeClasses"
import { Spinner } from "./Spinner"

const VARIANT_DANGER = "danger"
const VARIANT_LINK = "link"
const VARIANT_PRIMARY = "primary"
const VARIANT_PRIMARY_ALTERNATE = "alternate"
const VARIANT_SECONDARY = "secondary"
const VARIANT_BORDERLESS = "borderless"

const variantClasses: { [key: string]: string } = {
  [VARIANT_DANGER]: classes.danger,
  [VARIANT_LINK]: classes.link,
  [VARIANT_PRIMARY]: classes.primary,
  [VARIANT_PRIMARY_ALTERNATE]: classes.primaryAlternate,
  [VARIANT_SECONDARY]: classes.secondary,
  [VARIANT_BORDERLESS]: classes.borderless,
}

interface ButtonProps<T> extends ButtonHTMLAttributes<T> {
  external?: boolean
  linkTo?: string
  loading?: boolean
  compact?: boolean
  variant?: string
}

export const Button: React.FC<ButtonProps<any>> = ({
  className,
  external = false,
  linkTo,
  loading, // accepts undefined for styling as loadable button
  compact = false,
  variant = VARIANT_PRIMARY,
  children,
  ...props
}) => {
  const cn = composeClasses(
    classes.Button,
    variantClasses[variant],
    props.disabled && classes.disabled,
    className,
    compact && classes.compact,
    loading !== undefined && classes.loadable,
    loading === true && classes.loading
  )
  const spinner = () => {
    return (
      <div className={classes.loader}>
        <Spinner size="small" color="var(--background-color)" />
      </div>
    )
  }
  return linkTo !== undefined ? (
    external ? (
      <a
        href={linkTo}
        className={cn}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
        onClick={(e) => {
          if (props.disabled) {
            e.preventDefault()
          }
        }}
      >
        {loading ? spinner() : null}
        {children}
      </a>
    ) : (
      <Link
        to={linkTo}
        className={cn}
        {...props}
        onClick={(e) => {
          if (props.disabled) {
            e.preventDefault()
          }
        }}
      >
        {loading ? spinner() : null}
        {children}
      </Link>
    )
  ) : (
    <button className={cn} {...props}>
      {loading ? spinner() : null}
      {children}
    </button>
  )
}

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  variant?: string
}

export const FileInput: React.FC<InputProps> = ({
  className,
  variant = VARIANT_PRIMARY,
  children,
  ...props
}) => {
  const cn = composeClasses(
    classes.Button,
    variantClasses[variant],
    props.disabled && classes.disabled,
    className
  )

  return (
    <label className={cn}>
      <input type="file" {...props} />
      {children}
    </label>
  )
}
