import React from "react"
import { PageContainer } from "./PageContainer"

export const NotFound = () => (
  <section>
    <PageContainer header={{ title: "Niet gevonden", introduction: "Sorry, hier ging iets mis. Ga terug of kies via het menu de juiste bestemming." }}>
      
    </PageContainer>
  </section>
)
