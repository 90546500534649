import React from "react"
import { Link } from "react-router-dom"

import classes from "./Footer.module.css"
import { Container, SocialLinks } from "."
import { Button } from "./Button"

const Links = () => (
  <div className={classes.links}>
    <h3>Handig:</h3>
    <ul>
      <li>
        <Link to="/info">Contact</Link>
      </li>
      <li>
        <Link to="/privacy">Privacy</Link>
      </li>
      <li>
        <a href="/rss" target="_blank">
          RSS
        </a>
      </li>
    </ul>
  </div>
)

const Subscribe = () => (
  <div className={classes.subscribe}>
    <h3>Strips en nieuwtjes in je mail?</h3>
    <Button linkTo="http://eepurl.com/GHWKX" external>
      Abonneren via Mailchimp
    </Button>
  </div>
)

const Social = () => (
  <div className={classes.social}>
    <h3>Volg Fotostrips:</h3>
    <SocialLinks />
  </div>
)

export const Footer = () => {
  return (
    <footer>
      <div className={classes.Footer}>
        <Container className={classes.content}>
          <Links />
          <Subscribe />
          <Social />
        </Container>
      </div>
    </footer>
  )
}
