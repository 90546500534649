import { Order } from "../../../api/schema"
import { CartItem } from "./CartItem"
import { CustomerInfo } from "./CustomerInfo"

export const encodeNotes = (notes: string[]) => {
  const nonEmpty = notes.filter((n) => n.length > 0)
  return nonEmpty.length === 1
    ? nonEmpty[0]
    : nonEmpty.map((n, i) => `${i + 1}. ${n}`).join("\n")
}

export const orderWithCart = ({
  items,
  customer,
}: {
  items: CartItem[]
  customer: CustomerInfo
}): Partial<Order> => ({
  items: items.map(({ book, notes, price, quantity, title, vat }) => ({
    book,
    notes: encodeNotes(notes),
    price,
    quantity,
    title,
    vat,
  })),
  first_name: customer.firstName,
  last_name: customer.lastName,
  email: customer.email,
  address: customer.address,
  postal_code: customer.postalCode,
  city: customer.city,
  country: customer.country as string,
  save_user_info: customer.saveInfoForUser,
  subscribe_mail: customer.subscribe,
})
