import React, { ReactNode } from "react"
import classes from "./StripContainer.module.css"

interface StripContainerProps {
  children: ReactNode
}

const StripContainer: React.FC<StripContainerProps> = ({ children }) => (
  <div className={classes.container}>
    <div className={classes.content}>{children}</div>
  </div>
)

export default StripContainer
