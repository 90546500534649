import React from "react"
import { Image as ImageModel } from "../api/schema"

export const Image: React.FC<
  ImageModel & React.ImgHTMLAttributes<HTMLImageElement>
> = ({ alt, description, height, width, src, srcSet, ...props }) => (
  <picture>
    <img alt={alt ?? ""} {...props} src={src} srcSet={srcSet} />
  </picture>
)

export const placeholder = (alt: string): ImageModel => ({
  src: "https://via.placeholder.com/560x420.png", // TODO design placeholders for book, strip, series, & user
  srcSet: "",
  width: 560,
  height: 420,
  alt,
})
