import React, { useEffect, useState } from "react"

import classes from "./Pagination.module.css"
import { NavLink, matchPath, useLocation } from "react-router-dom"

interface PaginationProps {
  limit?: number
  links: { path: string; name: string }[]
  onSelect?: (index: number) => void
}

export const Pagination: React.FC<PaginationProps> = ({
  limit = 10,
  links,
  onSelect,
}) => {
  const { pathname } = useLocation()

  const activeLinkIndex = links.findIndex((l) => {
    const match = matchPath(pathname, { path: l.path })
    if (match) return true
    return false
  })

  const [focusIndex, setFocusIndex] = useState(
    activeLinkIndex !== -1 ? activeLinkIndex : links.length - 1
  )

  useEffect(() => {
    setFocusIndex(activeLinkIndex !== -1 ? activeLinkIndex : links.length - 1)
  }, [activeLinkIndex, links.length])

  const distToEnd = links.length - 1 - focusIndex
  const distToStart = focusIndex

  let maxDistance = limit / 2

  if (distToStart < limit / 2) {
    maxDistance += limit / 2 - distToStart
  } else if (distToEnd < limit / 2) {
    maxDistance += limit / 2 - distToEnd
  }

  const visibleIndices = links
    .map((_, idx) => idx)
    .filter((index) => {
      return (
        index !== 0 &&
        index !== links.length - 1 &&
        Math.abs(index - focusIndex) < maxDistance
      )
    })

  const handleClickPrev = () => {
    setFocusIndex((i) => i - 1)
  }

  const handleClickNext = () => {
    setFocusIndex((i) => i + 1)
  }

  const prev =
    links.length > 2 && !visibleIndices.includes(1) ? (
      <li className={classes.pageItem}>
        <button onClick={handleClickPrev}>&hellip;</button>
      </li>
    ) : null

  const next =
    links.length > 2 && !visibleIndices.includes(links.length - 2) ? (
      <li className={classes.pageItem}>
        <button onClick={handleClickNext}>&hellip;</button>
      </li>
    ) : null

  const elementForIndex = (index: number) => {
    if (index < 0 || index >= links.length) {
      return null
    }
    const link = links[index]
    return (
      <li className={classes.pageItem} key={link.path}>
        <NavLink
          to={link.path}
          activeClassName={classes.active}
          onClick={() => onSelect?.(index)}
        >
          {link.name}
        </NavLink>
      </li>
    )
  }

  return (
    <nav>
      <ul className={classes.pagination}>
        {links.length > 0 ? elementForIndex(0) : null}
        {prev}
        {visibleIndices.map(elementForIndex)}
        {next}
        {links.length > 1 ? elementForIndex(links.length - 1) : null}
      </ul>
    </nav>
  )
}
