import React from "react"
import { NavLink } from "react-router-dom"

import classes from "./NavigationMenu.module.css"
import CartMenu from "../features/cart/CartMenu"
import UserMenu from "../features/user/UserMenu"

type NavigationMenuLink = {
  name: string
  path: string
  exact?: boolean
  isActive?: any
}

export const NavigationMenu: React.FC = () => {
  const links: NavigationMenuLink[] = [
    {
      name: "Fotostrips",
      path: "/",
      exact: true,
      isActive: (_: unknown, { pathname }: Location) =>
        pathname === "/" || pathname.startsWith("/strip/"),
    },
    { name: "Archief", path: "/archief" },
    { name: "Series", path: "/series" },
    { name: "Winkel", path: "/winkel" },
    { name: "Info", path: "/info" },
  ]

  return (
    <div className={classes.NavigationMenu}>
      <div className={classes.scroller}>
        <ol className={classes.pages}>
          {links.map((link) => (
            <li key={link.name}>
              <NavLink
                className={classes.text}
                to={link.path}
                exact={link.exact}
                activeClassName={classes.active}
                isActive={link.isActive}
                title={link.name}
              >
                <span>{link.name}</span>
              </NavLink>
            </li>
          ))}
        </ol>
      </div>
      <ul className={classes.personal}>
        <CartMenu />
        <UserMenu />
      </ul>
    </div>
  )
}
