import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit"

import { getMainSeries, getSeries, getSeriesList } from "../../api/rest"
import { Series, Strip } from "../../api/schema"
import { login, logout } from "../user/userSlice"
import { AppState } from "../../store"

type SeriesState = {
  isLoading: boolean
  error?: string
  allSeries: Series[]
  strips: Strip[]
}

const initialState: SeriesState = {
  isLoading: false,
  allSeries: [],
  strips: [],
}

const discard = (state: SeriesState) => {
  state.allSeries = []
  state.strips = []
}

export const fetchAll = createAsyncThunk("series/fetchAll", getSeriesList)
export const fetchOne = createAsyncThunk("series/fetchOne", getSeries)
export const fetchMain = createAsyncThunk("series/fetchMain", getMainSeries)

const seriesSlice = createSlice({
  name: "series",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAll.pending, (state) => {
        state.isLoading = true
        state.error = undefined
      })
      .addCase(fetchAll.fulfilled, (state, action) => {
        state.allSeries = action.payload
        state.error = undefined
        state.isLoading = false
      })
      .addCase(fetchAll.rejected, (state, action) => {
        state.error = action.error.message
        state.isLoading = false
      })

    builder
      .addCase(fetchMain.pending, (state, action) => {
        state.error = undefined
        state.isLoading = true
        state.strips = []
      })
      .addCase(fetchMain.fulfilled, (state, action) => {
        const index = state.allSeries.findIndex(
          (s) => s.id === action.payload.id
        )
        if (index !== -1) {
          state.allSeries[index] = action.payload
        } else {
          state.allSeries.push(action.payload)
        }
        state.isLoading = false
      })
      .addCase(fetchMain.rejected, (state, action) => {
        state.error = action.error.message
        state.isLoading = false
      })

    builder
      .addCase(fetchOne.pending, (state, action) => {
        state.error = undefined
        state.isLoading = true
        if (
          state.strips.length &&
          typeof state.strips[0].series == "object" &&
          state.strips[0].series.slug !== action.meta.arg
        ) {
          // Remove strips if a different series is being fetched.
          state.strips = []
        }
      })
      .addCase(fetchOne.fulfilled, (state, action) => {
        const { strips, ...series } = action.payload
        const index = state.allSeries.findIndex((s) => s.slug === series.slug)
        if (index !== -1) {
          state.allSeries[index] = series as Series
        } else {
          state.allSeries.push(series as Series)
        }
        state.strips = ((strips ?? []) as Strip[])
          .filter((strip) => strip.featured)
          .sort((lhs: Strip, rhs: Strip) => {
            let lhsDate = new Date(`${lhs.published_on}`)
            let rhsDate = new Date(`${rhs.published_on}`)
            if (lhsDate < rhsDate) {
              return -1
            }
            if (lhsDate > rhsDate) {
              return 1
            }
            return 0
          })
          .map((strip: Strip) => ({ ...strip, series } as Strip))
        state.isLoading = false
      })
      .addCase(fetchOne.rejected, (state, action) => {
        state.error = action.error.message
        state.isLoading = false
      })

    builder.addCase(login.fulfilled, discard).addCase(logout.fulfilled, discard)
  },
})

export const selectYpeSeries = createSelector(
  (state: AppState) => state.series,
  (series) => series.allSeries.find((s) => s.id === 1)
)

export const selectOtherSeries = createSelector(
  (state: AppState) => state.series,
  (series) => ({
    ...series,
    allSeries: series.allSeries.filter((s) => s.id !== 1),
  })
)

export default seriesSlice.reducer
