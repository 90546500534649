import React from "react"

import classes from "./ActionModal.module.css"
import { composeClasses } from "../util/composeClasses"

type ActionModalProps = {
  open?: boolean
  onDismiss: () => void
}

export const ActionModal: React.FC<
  ActionModalProps & React.HTMLAttributes<HTMLDivElement>
> = ({ open = false, children, onDismiss }) => {
  return (
    <div
      className={composeClasses(classes.ActionModal, open && classes.opened)}
      title="" // To avoid the source button's tooltip being shown over the modal
    >
      <div className={classes.backdrop} onClick={onDismiss} />
      <div className={classes.contents}>
        <div style={{ pointerEvents: open ? "all" : "none" }}>{children}</div>
      </div>
    </div>
  )
}
