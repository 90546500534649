import type { File } from "./schema"

const ASSETS_BASE_URL = process.env.REACT_APP_CLOUDFRONT_URL

type TransformationKey =
  | "avatar"
  | "panel"
  | "panel2x"
  | "thumbnail"
  | "thumbnail2x"
  | "opengraph"

/** Build absolute asset URL (with optional thumbnail key) */
function assetURL(id: string, filename: string, key?: TransformationKey) {
  const url = new URL(`/assets/${id}/${filename}`, ASSETS_BASE_URL)
  if (key) {
    url.searchParams.append("key", key)
  }
  return url
}

export interface Image {
  src: string
  srcSet: string
  alt: string
  description?: string | null
  width?: number
  height?: number
}

export function panelAsset(file: File): Image {
  const { id, filename_download, description, title, width, height } = file
  const panel = assetURL(id, filename_download, "panel").href
  const panel2x = assetURL(id, filename_download, "panel2x").href
  return {
    src: panel2x,
    srcSet: `${panel}, ${panel2x} 2x`,
    alt: description ?? title ?? filename_download,
    width: width ?? undefined,
    height: height ?? undefined,
  }
}

export function thumbAsset(file: File, alt?: string): Image {
  const { id, filename_download, width, height } = file
  const thumbnail = assetURL(id, filename_download, "thumbnail").href
  const thumbnail2x = assetURL(id, filename_download, "thumbnail2x").href
  return {
    src: thumbnail,
    srcSet: [thumbnail, `${thumbnail2x} 2x`].join(", "),
    alt: alt ?? file.description ?? file.title ?? file.filename_download,
    width: width ?? undefined,
    height: height ?? undefined,
  }
}

export function avatarAsset(file: File, alt: string): Image {
  const { id, filename_download, width, height } = file
  const avatar = assetURL(id, filename_download, "avatar").href
  return {
    src: avatar,
    srcSet: avatar,
    alt,
    width: width ?? undefined,
    height: height ?? undefined,
  }
}

export function asset(
  file: File,
  alt?: string,
  key?: TransformationKey
): Image {
  const { id, filename_download, description, width, height } = file
  return {
    src: assetURL(id, filename_download, key).href,
    srcSet: ``,
    alt: alt ?? file.description ?? file.title ?? file.filename_download,
    description,
    width: width ?? undefined,
    height: height ?? undefined,
  }
}
