import React, { useEffect } from "react"
import { useSelector } from "react-redux"

import { fetchPaymentMethods } from "./cartSlice"
import { AppState, useAppDispatch } from "../../store"

import classes from "./PaymentMethodsIcons.module.css"
import { Country } from "./types/Country"
import { Spinner } from "../../components"

interface PaymentMethodsIconsProps {
  amount?: number
  country?: Country
}

export const PaymentMethodsIcons: React.FC<PaymentMethodsIconsProps> = ({
  amount,
  country,
}) => {
  const dispatch = useAppDispatch()
  const { isLoadingPaymentMethods, paymentMethods } = useSelector(
    (state: AppState) => state.cart
  )

  let locale: string
  switch (country) {
    case Country.BE:
      locale = "nl_BE"
      break
    case Country.NL:
    default:
      locale = "nl_NL"
      break
  }

  useEffect(() => {
    dispatch(fetchPaymentMethods({ amount, locale }))
  }, [amount, dispatch, locale])

  if (isLoadingPaymentMethods) {
    return (
      <div style={{ textAlign: "center", margin: "12px 0" }}>
        <Spinner size="small" />
      </div>
    )
  }

  if (paymentMethods === null) {
    return null
  }

  return (
    <ul className={classes.paymentMethods}>
      {paymentMethods.map((method) => (
        <li key={method.id}>
          <img
            src={method.image.svg}
            alt={method.description}
            title={method.description}
          />
        </li>
      ))}
    </ul>
  )
}
