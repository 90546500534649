import React, { ReactNode, useRef, useState } from "react"

import classes from "./Perspective.module.css"

interface PerspectiveProps {
  children: ReactNode
  hasCaption?: boolean
  maxDegrees?: number
}

export const Perspective: React.FC<PerspectiveProps> = ({
  children,
  hasCaption = false,
  maxDegrees = 1.0,
}) => {
  const isRotationEnabled = maxDegrees !== 0
  const [innerStyle, setInnerStyle] = useState({})
  const container = useRef<HTMLDivElement | null>(null)
  const inner = useRef<HTMLDivElement | null>(null)

  const update = (event: React.MouseEvent) => {
    if (!container.current) {
      return
    }
    const { x, y } = normalizedPosition(event, container.current)
    const xRot = (y * maxDegrees * -1).toFixed(2)
    const yRot = (x * maxDegrees).toFixed(2)
    setInnerStyle({ transform: `rotateX(${xRot}deg) rotateY(${yRot}deg)` })
  }

  const handleEnter = (event: React.MouseEvent) => {
    if (isRotationEnabled) {
      update(event)
    }
  }

  const handleLeave = () => {
    setInnerStyle({})
  }

  const handleMove = (event: React.MouseEvent) => {
    if (isRotationEnabled) {
      update(event)
    }
  }

  const classNames = [classes.outer]
  if (hasCaption) {
    classNames.push(classes.aboveCaption)
  }

  return (
    <div
      className={classNames.join(" ")}
      ref={container}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      onMouseMove={handleMove}
    >
      <div className={classes.lift}>
        <div className={classes.inner} ref={inner} style={innerStyle}>
          {children}
        </div>
      </div>
    </div>
  )
}

/**
 * Returns the cursor's position on the element in terms of the element's size,
 * using the center as the origin (`x` and `y` ranging from `-0.5` to `0.5`).
 */
function normalizedPosition(
  clientPosition: { clientX: number; clientY: number },
  element: HTMLElement
) {
  const { clientX, clientY } = clientPosition
  const center = boundingRectCenter(element)
  const x = (clientX - center.x) / element.clientWidth
  const y = (clientY - center.y) / element.clientHeight
  return { x, y }
}

function boundingRectCenter(element: HTMLElement) {
  const { x, y, width, height } = element.getBoundingClientRect()
  return {
    x: x + Math.floor(width / 2),
    y: y + Math.floor(height / 2),
  }
}
