import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Container, PageContainer } from "../../components/PageContainer"
import { AppState, useAppDispatch } from "../../store"
import { useSelector } from "react-redux"
import { NotFound, Spinner } from "../../components"
import ReactMarkdown from "react-markdown"
import { fetchPage } from "./pageSlice"

export const Page: React.FC = () => {
  const { slug } = useParams<{ slug: string }>()
  const dispatch = useAppDispatch()
  const { page, error, isLoading } = useSelector(
    (state: AppState) => state.page
  )

  useEffect(() => {
    dispatch(fetchPage(slug))
  }, [dispatch, slug])

  if (error) {
    return <NotFound />
  }

  if (isLoading || page?.slug !== slug) {
    return (
      <Container>
        <Spinner centered />
      </Container>
    )
  }

  return (
    <PageContainer header={{ title: page.header }} readableWidth>
      <section>
        <ReactMarkdown>{page.content}</ReactMarkdown>
      </section>
    </PageContainer>
  )
}
