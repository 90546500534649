import React from "react"
import ReactMarkdown from "react-markdown"
import { Helmet } from "react-helmet-async"

import classes from "./PageHeader.module.css"

export type PageHeaderProps = {
  title: string
  introduction?: string
  imageURL?: string
  metaDescription?: string
}

export const PageHeader = ({
  title,
  introduction,
  imageURL,
  metaDescription,
}: PageHeaderProps) => {
  return (
    <div className={classes.PageHeader}>
      {metaDescription && (
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={metaDescription} />
        </Helmet>
      )}
      {imageURL && (
        <div className={classes.topBar}>
          <div
            className={classes.coverImage}
            style={{ backgroundImage: `url(${imageURL})` }}
          />
        </div>
      )}
      <div className={classes.content}>
        <h1>{title}</h1>
        {introduction && <ReactMarkdown>{introduction}</ReactMarkdown>}
      </div>
    </div>
  )
}
