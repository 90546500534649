import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import { HelmetProvider } from "react-helmet-async"

import "./styles/index.css"
import App from "./App"
import { MatomoTag } from "./analytics"
import * as serviceWorker from "./serviceWorker"
import store from "./store"

ReactDOM.render(
  <React.StrictMode>
    <MatomoTag url={process.env.REACT_APP_MATOMO_CONTAINER_URL}>
      <Provider store={store}>
        <HelmetProvider>
          <Router basename="/">
            <App />
          </Router>
        </HelmetProvider>
      </Provider>
    </MatomoTag>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
