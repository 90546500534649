import React from "react"
import { FiShoppingBag } from "react-icons/fi"

import { useSelector } from "react-redux"
import { AppState } from "../../store"

import { IconMenuButton } from "../../components"
import { useHistory } from "react-router-dom"

const CartMenu = () => {
  const VIEW_CART = "/winkel/mandje"

  const history = useHistory()

  const { items, order } = useSelector((state: AppState) => state.cart)

  const handleSelection = (value: any) => {
    switch (value) {
      case VIEW_CART:
        history.push(value)
        break
      default:
        throw new Error("Unhandled menu item: " + value)
    }
  }

  if (items.length === 0 && order?.status !== "created") {
    return null
  }

  const totalItems = items.reduce((value, item) => value + item.quantity, 0)

  return (
    <li title="Winkelmandje">
      <IconMenuButton
        icon={<FiShoppingBag />}
        name="Bestelling"
        onSelect={handleSelection}
        badge={order?.status === "created" || totalItems}
        path="/winkel/mandje"
      />
    </li>
  )
}

export default CartMenu
