import React from "react"
import ReactMarkdown from "react-markdown"

import { GenericBanner } from "../../api/schema"

import classes from "./Banner.module.css"
import { Button } from "../Button"
// import ype2x from "./ype8@2x.png"
import alpaca from "./alpaca-pinguin.png"
import { composeClasses } from "../../util/composeClasses"

interface BannerProps extends React.HTMLProps<HTMLDivElement> {
  banner: GenericBanner
  header: string
}

export const Banner: React.FC<BannerProps> = ({
  banner,
  className,
  header,
  ...props
}) => (
  <div
    className={composeClasses(
      className,
      classes.banner,
      classes.withImageBackground
    )}
    {...props}
    style={{
      background: `#ffbf00 url(${alpaca}) 95% bottom / 90px no-repeat`,
    }}
  >
    <h2>
      {banner.button_url ? <a href={banner.button_url}>{header}</a> : header}
    </h2>

    {banner.text && <ReactMarkdown>{banner.text}</ReactMarkdown>}

    {banner.button_url && (
      <Button
        className={classes.button}
        variant="primary"
        linkTo={banner.button_url}
        external={true}
      >
        {banner.button_label}
      </Button>
    )}
  </div>
)
