import React, { useState, useMemo } from "react"

import classes from "./FormField.module.css"
import { composeClasses } from "../util/composeClasses"

export type FormFieldProps = React.HTMLProps<
  HTMLInputElement | HTMLSelectElement
> & {
  name: string
  options?: { label: string; value: string }[]
}

export const FormField: React.FC<FormFieldProps> = ({
  className,
  type,
  options = [],
  ref, // destructuring to remove from `config`
  style,
  ...config
}) => {
  const [hasFocus, setHasFocus] = useState(false)

  const showsLabel = useMemo(
    () => hasFocus || !!config.value || !!config.defaultValue,
    [hasFocus, config.value, config.defaultValue]
  )

  let fieldElement: JSX.Element

  if (type === "select") {
    fieldElement = (
      <select
        {...config}
        className={classes.field}
        onFocus={() => setHasFocus(true)}
        onBlur={(e) => {
          setHasFocus(false)
          config.onBlur?.(e)
        }}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    )
  } else {
    fieldElement = (
      // div wrapper needed to workaround baseline alignment bug in Safari, see: https://github.com/philipwalton/flexbugs/issues/270
      <div className={classes.fieldDiv}>
        <input
          {...config}
          type={type}
          className={classes.field}
          readOnly={config.disabled}
          onFocus={() => setHasFocus(true)}
          onBlur={(e) => {
            setHasFocus(false)
            config.onBlur?.(e)
          }}
        />
      </div>
    )
  }

  return (
    <label
      key={config.name}
      className={composeClasses(classes.fieldWrapper, className)}
      style={style}
    >
      <span
        className={composeClasses(classes.label, showsLabel || classes.hidden)}
      >
        {config.label ?? config.placeholder ?? config.name}
      </span>
      {fieldElement}
    </label>
  )
}
