import React from "react"

import classes from "./Tags.module.css"
import { Link } from "react-router-dom"

interface TagsProps {
  tags: string[]
}

export const Tags: React.FC<TagsProps> = ({ tags }) => {
  return (
    <ul className={classes.tags}>
      {tags.map((tag) => (
        <li key={tag}>
          <Link to={`/archief/search/${tag}`}>{tag}</Link>
        </li>
      ))}
    </ul>
  )
}
