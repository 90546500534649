import React, { useRef, useState } from "react"
import Editor from "react-avatar-editor"

import { Image } from "../../api/schema"

import classes from "./AvatarEditor.module.css"
import { Button } from "../../components"

export const AvatarEditor = ({
  avatar,
  onCancel,
  onSave,
}: {
  avatar: Image
  onCancel?: () => void
  onSave: (data: FormData) => void
}) => {
  const [scale, setScale] = useState(1.0)
  const editorRef = useRef<Editor>(null)

  const minScale = 1
  const maxScale = 3
  const scaleRange = maxScale - minScale
  const inputRange = 200.0

  const slider = (
    <input
      className={classes.slider}
      type="range"
      min={0}
      max={inputRange}
      value={((scale - minScale) / scaleRange) * inputRange}
      onChange={(e) => {
        setScale(
          (parseFloat(e.target.value) / inputRange) * scaleRange + minScale
        )
      }}
    />
  )

  const handleSaveAvatar = () => {
    editorRef.current?.getImage().toBlob((blob) => {
      if (blob) {
        const formData = new FormData()
        formData.append("file", blob, "avatar.png")
        onSave(formData)
      }
    })
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.editor}>
        <Editor
          ref={editorRef}
          image={avatar.src}
          width={120}
          height={120}
          border={8}
          borderRadius={60}
          scale={scale}
          color={[251, 250, 247, 0.8]}
          crossOrigin="anonymous"
        />
        <br />
        {slider}
      </div>

      <div>
        {onCancel ? (
          <Button variant="secondary" onClick={onCancel}>
            Annuleer
          </Button>
        ) : null}

        <Button variant="primary" onClick={handleSaveAvatar}>
          Bewaar
        </Button>
      </div>

      <p>
        <small>
          Let op: door een afbeelding te uploaden, verklaar je over het
          auteursrecht te beschikken. Een portretfoto van jezelf vinden we het
          leukst!
        </small>
      </p>
    </div>
  )
}
