import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Link, Redirect, useLocation } from "react-router-dom"

import { AppState, useAppDispatch } from "../../store"
import { activate, showLogin } from "./userSlice"

import { Spinner, Button, Notice } from "../../components"

const Activation = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const token = params.get("token")
  const { accountStatus, error, user, isLoading } = useSelector(
    (state: AppState) => state.user
  )

  useEffect(() => {
    if (
      !user &&
      !isLoading &&
      token &&
      accountStatus === undefined &&
      error === undefined
    ) {
      dispatch(activate(token))
    }
  }, [accountStatus, error, dispatch, token, user, isLoading])

  const handleRetry = () => {
    dispatch(activate(token!))
  }

  const handleLogin = (event: React.MouseEvent) => {
    event.preventDefault()
    dispatch(showLogin())
  }

  if (user !== undefined) {
    return <Redirect to="/account" />
  }

  if (!token && accountStatus === undefined) {
    return <Redirect to="/" />
  }

  if (error) {
    return (
      <Notice>
        {error}
        <br />
        <br />
        <Button onClick={handleRetry} variant="secondary">
          Opnieuw proberen
        </Button>{" "}
        <Button onClick={handleLogin}>Log in</Button>
      </Notice>
    )
  }

  if (accountStatus === "activated") {
    return (
      <Notice>
        Je account is geactiveerd. Je kunt nu{" "}
        <Link to="/login" onClick={handleLogin}>
          inloggen
        </Link>
        .
      </Notice>
    )
  }

  return <Spinner centered />
}

export default Activation
