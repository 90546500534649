import React, { useCallback, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"

import { fetchBooks } from "./booksSlice"
import { AppState, useAppDispatch } from "../../store"
import { Series } from "../../api/schema"

import { PageHeader, Spinner, EmptyMessage } from "../../components"
import { BooksSection } from "./BooksSection"

const Books = () => {
  const dispatch = useAppDispatch()
  const { series, books, isLoading } = useSelector((s: AppState) => s.books)
  const booksWithoutSeries = books.filter((b) => b.series === null)
  const isEmpty = books.length === 0 || series.length === 0

  const filteredBooks = useCallback(
    (series: Series) =>
      books.filter((book) =>
        typeof book.series == "number"
          ? book.series === series.id
          : book.series?.id === series.id
      ),
    [books]
  )

  const seriesWithMultipleBooks = useMemo(
    () => series.filter((s) => filteredBooks(s).length > 1),
    [filteredBooks, series]
  )

  const singletonBooks = useMemo(
    () =>
      series.flatMap((s) => {
        const bs = filteredBooks(s)
        if (bs.length === 1) {
          return bs[0]
        } else {
          return []
        }
      }),
    [filteredBooks, series]
  )

  useEffect(() => {
    if (isEmpty) {
      dispatch(fetchBooks())
    }
  }, [dispatch, isEmpty])

  // Make anchor links work after content is fetched:
  useEffect(() => {
    const anchor = window.location.hash.slice(1)
    if (!isEmpty && anchor) {
      const el = document.getElementById(anchor)
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "start" })
      }
    }
  }, [isEmpty])

  return (
    <>
      <PageHeader
        title="Winkel"
        introduction="Hier vind je alle fotostripboeken van Ype, 3Hoog, De VrijMiBoys en Yves+Guillaume. Ype signeert je boeken graag. Wil je ergens anders laten bezorgen dan Nederland of België? Mail dan naar shop@fotostrips.nl."
        metaDescription="Bestel gesigneerde fotostripboeken van Ype, 3Hoog en De VrijMiBoys."
      />
      {isLoading ? (
        !series.length && <Spinner centered />
      ) : (
        <EmptyMessage show={!series.length}>Geen boeken gevonden.</EmptyMessage>
      )}
      {seriesWithMultipleBooks.map((series) => (
        <BooksSection
          key={series.slug}
          series={series}
          books={filteredBooks(series)}
          enableOrderButton
        />
      ))}
      {booksWithoutSeries.length > 0 || singletonBooks.length > 0 ? (
        <BooksSection
          header="Overig"
          books={[...singletonBooks, ...booksWithoutSeries]}
          enableOrderButton
        />
      ) : null}
    </>
  )
}

export default Books
