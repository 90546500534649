import { createSelector, createSlice } from "@reduxjs/toolkit"
import { Strip } from "../../api/schema"
import { AppState } from "../../store"

type StripState = {
  index?: number
  requestedId?: number
  strips: Strip[]
}

const initialState: StripState = {
  strips: [],
}

const stripSlice = createSlice({
  name: "strip",
  initialState,
  reducers: {
    open(state, action) {
      const { strips, id, index } = action.payload
      state.strips = strips
      if (id !== undefined) {
        const nid = Number(id)
        const idx = state.strips.findIndex((s) => s.id === nid)
        if (idx !== -1) {
          state.index = idx
          state.requestedId = undefined
        } else {
          state.index = undefined
          state.requestedId = id
        }
      } else {
        state.index = index || 0
        state.requestedId = undefined
      }
    },
    next(state) {
      if (state.index !== undefined && state.index + 1 < state.strips.length) {
        state.index += 1
        state.requestedId = undefined
      }
    },
    previous(state) {
      if (state.index !== undefined && state.index > 0) {
        state.index -= 1
        state.requestedId = undefined
      }
    },
    close(state) {
      state.strips = []
      state.index = undefined
      state.requestedId = undefined
    },
  },
})

/**
 * Selector to retrieve the currently opened strip.
 */
export const currentStrip = createSelector(
  (state: AppState) => state.strip,
  (state) => (state.index !== undefined ? state.strips[state.index] : undefined)
)

/**
 * Selector to retrieve the strip before the currently opened one, if any.
 */
export const selectPreviousStrip = createSelector(
  (state: AppState) => state.strip,
  (state) =>
    state.index !== undefined && state.index > 0
      ? state.strips[state.index - 1]
      : null
)

/**
 * Selector to retrieve the strip after the currently opened one, if any.
 */
export const selectNextStrip = createSelector(
  (state: AppState) => state.strip,
  (state) =>
    state.index !== undefined && state.index < state.strips.length
      ? state.strips[state.index + 1]
      : null
)

export const { open, next, previous, close } = stripSlice.actions

export default stripSlice.reducer
