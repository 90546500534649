import React from "react"
import { Link } from "react-router-dom"

import { Book } from "../../api/schema"

import classes from "./BookCard.module.css"
import BookOrderButton from "./BookOrderButton"
import { BookThumb, Button } from "../../components"
import { formatMonth } from "../../util/moment"

type BookCardProps = {
  book: Book
  enableOrderButton?: boolean
  enableInfoLink?: boolean
  enableShopLink?: boolean
}

const makeLink = (slug: string | undefined, children: React.ReactNode) => {
  if (slug === undefined) {
    return children
  }
  return <Link to={`/winkel/${slug}`}>{children}</Link>
}

export const BookCard: React.FC<BookCardProps> = ({
  book,
  enableOrderButton = false,
  enableInfoLink = false,
  enableShopLink = false,
}) => {
  const { title, slug, blurb, published_on } = book

  return (
    <div className={classes.BookCard} id={slug}>
      <div className={classes.cover}>
        {makeLink(slug, <BookThumb book={book} />)}
      </div>

      <div className={classes.main}>
        <h3>{makeLink(slug, title)}</h3>

        <small>
          {published_on !== undefined ? (
            <span>{formatMonth(published_on)}</span>
          ) : null}
        </small>

        {blurb && <p>{blurb}</p>}

        {(enableOrderButton || enableInfoLink || enableShopLink) && (
          <div className={classes.buttons}>
            {enableOrderButton && <BookOrderButton book={book} />}

            {enableInfoLink && slug && (
              <Button
                linkTo={`/winkel/${slug}`}
                variant={enableOrderButton ? "secondary" : "primary"}
              >
                Details
              </Button>
            )}

            {enableShopLink && (
              <Button
                linkTo="/winkel"
                variant={
                  enableOrderButton || enableInfoLink ? "secondary" : "primary"
                }
              >
                Alle boeken
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
