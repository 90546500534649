import React, { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"

import { AppState, useAppDispatch } from "../../store"
import { fetchStrip, fetchStrips } from "./feedSlice"
import { open } from "../strip/stripSlice"

import { EmptyMessage, Notice, Spinner } from "../../components"
import StripPage from "../../components/StripPage"
import {
  currentStrip,
  selectNextStrip,
  selectPreviousStrip,
} from "../strip/stripSlice"

/**
 * Container that fetches the feed and opens strips in a modal.
 */
const Feed = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { id } = useParams<{ id?: string }>()
  const { strips, isLoading, isLoadingStrip, deepStrip, error } = useSelector(
    (state: AppState) => state.feed
  )
  const strip = useSelector(currentStrip)
  const previousStrip = useSelector(selectPreviousStrip)
  const nextStrip = useSelector(selectNextStrip)
  const role = useSelector((state: AppState) => state.user.user?.role)

  const handlePrevious = useMemo(() => {
    return previousStrip
      ? () => history.replace(`/strip/${previousStrip.id}`)
      : () => history.push(`/archief`)
  }, [history, previousStrip])

  const handleNext = useMemo(() => {
    return nextStrip
      ? () => history.replace(`/strip/${nextStrip.id}`)
      : undefined
  }, [history, nextStrip])

  // Fetch feed.
  useEffect(() => {
    dispatch(fetchStrips(7))
  }, [dispatch, role]) // Role included to refetch when user session changes.

  // Select strip(s) to open
  useEffect(() => {
    if (id) {
      // Open the strip with the ID requested in location:
      if (deepStrip) {
        dispatch(open({ strips: [deepStrip].concat(strips), id }))
      } else {
        dispatch(open({ strips, id }))
      }

      // Fetch a strip outside of loaded feed:
      const intID = parseInt(id)
      if (
        !isLoadingStrip &&
        deepStrip?.id !== intID &&
        strips.findIndex((s) => s.id === intID) === -1
      ) {
        dispatch(fetchStrip(intID))
      }
    } else if (strips.length) {
      // No id requested, open newest strip in feed
      const newestStripIdx = strips.findLastIndex(
        (s) => s.status === "published"
      )
      const index = newestStripIdx >= 0 ? newestStripIdx : strips.length - 1
      dispatch(open({ strips, index }))
    }
  }, [deepStrip, dispatch, id, isLoadingStrip, strips])

  // Reset scroll when presenting (another) strip.
  useEffect(() => {
    if (strip) {
      window.scrollTo({ top: 0 })
    }
  }, [strip])

  if (error) {
    return <Notice error>Fout: {error}</Notice>
  }

  if (isLoading || isLoadingStrip) {
    return <Spinner centered />
  }

  if (strip === undefined) {
    return null
  }

  return (
    <React.Fragment>
      {strips.length === 0 && !deepStrip ? (
        <EmptyMessage />
      ) : (
        <StripPage
          strip={strip}
          onPrevious={handlePrevious}
          onNext={handleNext}
          jumpsBack={!previousStrip}
        />
      )}
    </React.Fragment>
  )
}

export default Feed
