import React, { useCallback, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { useParams, useHistory, Switch, Route } from "react-router-dom"

import { fetchIndex, fetchKeywords } from "./archiveSlice"
import { AppState, useAppDispatch } from "../../store"

import ArchiveHeader from "./ArchiveHeader"
import ArchiveNavigationBar from "./ArchiveNavigationBar"
import ArchiveSearchResults from "./ArchiveSearchResults"
import ArchiveSection from "./ArchiveSection"
import { Notice } from "../../components"

const Archive: React.FC = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { year } = useParams<{ year?: string }>()
  const { error, sectionKeys } = useSelector((state: AppState) => state.archive)
  const keywords = useSelector((state: AppState) => state.archive.keywords)

  // Fetch keywords for search bar
  const hasFetchedKeywords = keywords !== undefined
  useEffect(() => {
    if (!hasFetchedKeywords) {
      dispatch(fetchKeywords())
    }
  }, [dispatch, hasFetchedKeywords])

  // Fetch sections list
  const hasFetchedIndex = sectionKeys !== undefined
  useEffect(() => {
    if (!hasFetchedIndex) {
      dispatch(fetchIndex())
    }
  }, [dispatch, hasFetchedIndex])

  // Redirect to newest section if we're not on the search page
  useEffect(() => {
    if (
      year === undefined &&
      sectionKeys?.length &&
      !history.location.pathname.startsWith("/archief/search")
    ) {
      history.replace(`/archief/${sectionKeys[sectionKeys.length - 1]}`)
    }
  }, [history, sectionKeys, year])

  // Find previous and next sections
  const [prevSectionKey, nextSectionKey] = useMemo(() => {
    const idx =
      year && sectionKeys?.length ? sectionKeys.indexOf(+year) : undefined
    const nextSectionKey =
      idx !== undefined && idx + 1 < sectionKeys!.length
        ? sectionKeys![idx + 1]
        : null
    const prevSectionKey = idx ? sectionKeys![idx - 1] : null
    return [prevSectionKey, nextSectionKey]
  }, [sectionKeys, year])

  const handlePrevious = useMemo(() => {
    return prevSectionKey === null
      ? undefined
      : () => history.push(`/archief/${prevSectionKey}`)
  }, [history, prevSectionKey])

  const handleNext = useMemo(() => {
    return nextSectionKey === null
      ? undefined
      : () => history.push(`/archief/${nextSectionKey}`)
  }, [history, nextSectionKey])

  const handleSearch = useCallback(
    (query: string) => {
      if (query) {
        const basePath = `/archief/search`
        if (!history.location.pathname.startsWith(basePath)) {
          history.push(basePath + "/" + query)
        } else {
          history.replace(basePath + "/" + query)
        }
      }
    },
    [history]
  )

  if (error) {
    return <Notice error>{error}</Notice>
  }

  return (
    <React.Fragment>
      <ArchiveHeader />
      <section>
        <ArchiveNavigationBar
          sectionKeys={sectionKeys}
          keywords={keywords ?? []}
          onSearch={handleSearch}
        />
        <Switch>
          <Route
            path={["/archief/search/:query/:id", "/archief/search/:query"]}
            component={ArchiveSearchResults}
          />
          <Route path={["/archief/:year/:id", "/archief/:year"]}>
            <ArchiveSection
              year={+year!}
              onNext={handleNext}
              onPrevious={handlePrevious}
            />
          </Route>
        </Switch>
      </section>
    </React.Fragment>
  )
}

export default Archive
