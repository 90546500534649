import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { Book, Series } from "../../api/schema"
import { getBooks, getBook } from "../../api/rest"
import { fetchSection } from "../archive/archiveSlice"
import { login, logout } from "../user/userSlice"

type BooksState = {
  isLoading: boolean
  error?: string
  series: Series[]
  books: Book[]
}

const initialState: BooksState = {
  isLoading: false,
  series: [],
  books: [],
}

const discard = (state: BooksState) => {
  state.books = []
  state.series = []
}

export const fetchBooks = createAsyncThunk("books/fetchAll", getBooks)
export const fetchBook = createAsyncThunk("books/fetchOne", getBook)

const booksSlice = createSlice({
  name: "books",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBooks.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchBooks.fulfilled, (state, action) => {
      const s = action.payload.reduce(
        (acc: { [key: string]: Series }, book) => {
          if (book.series && typeof book.series == "object") {
            let key = book.series.slug
            acc[key] = acc[key] ?? book.series
          }
          return acc
        },
        {}
      )
      const series = Object.values(s) as Series[]
      state.series = series.sort((lhs, rhs) => lhs.sort - rhs.sort)
      state.books = action.payload
        .map((book: Book) => ({ ...book, strips: null }))
        .sort((lhs, rhs) => (lhs.published_on > rhs.published_on ? -1 : 1))
      state.error = undefined
      state.isLoading = false
    })
    builder.addCase(fetchBooks.rejected, (state, action) => {
      state.error = action.error.message
      state.isLoading = false
    })

    builder.addCase(fetchBook.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchBook.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = undefined
      storeBooks(state, action.payload)
    })
    builder.addCase(fetchBook.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error.message
    })

    // Store books fetched for archive, for opening individually.
    builder.addCase(fetchSection.fulfilled, (state, action) => {
      storeBooks(state, action.payload.books)
    })

    builder.addCase(login.fulfilled, discard)
    builder.addCase(logout.fulfilled, discard)
  },
})

const storeBooks = (state: BooksState, book: Book | Book[] | null | number) => {
  if (book === null || typeof book == "number") return
  const books = ([] as Book[]).concat(book)
  books.forEach((book) => {
    const ix = state.books.findIndex((b) => b.id === book.id)
    if (ix !== -1) {
      state.books[ix] = book
    } else {
      state.books.push(book)
    }
  })
}

export default booksSlice.reducer
