import React, { ReactNode } from "react"

import classes from "./HeaderWithButtons.module.css"

type Props = {
  children: ReactNode
  tag?: string
  text: string
}

export const HeaderWithButtons: React.FC<Props> = ({
  tag: Tag = "h1",
  text,
  children,
}) => {
  return (
    <div className={classes.headerWithButtons}>
      {React.createElement(Tag, undefined, text)}
      <div>{children}</div>
    </div>
  )
}
