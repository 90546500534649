import React, {
  ChangeEvent,
  FormEvent,
  InputHTMLAttributes,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import classes from "./Autocomplete.module.css"

interface Token {
  value: string
}

interface AutocompleteProps<T extends Token> {
  onSearch: (value: string) => void
  suggestions: T[]
}

export const Autocomplete = <TokenType extends Token>({
  suggestions,
  onSearch,
  defaultValue,
  ...inputProps
}: AutocompleteProps<TokenType> & InputHTMLAttributes<HTMLInputElement>) => {
  const [activeSuggestion, setActiveSuggestion] = useState(0)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [userInput, setUserInput] = useState<string>(
    typeof defaultValue == "string" ? defaultValue : ""
  )
  const inputRef = useRef<HTMLInputElement>(null)

  const filteredSuggestions = useMemo(() => {
    if (!showSuggestions) {
      return []
    }
    const str = userInput.toLowerCase()
    return suggestions.filter((s) => s.value.toLowerCase().startsWith(str))
  }, [showSuggestions, suggestions, userInput])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserInput(e.currentTarget.value)
    setActiveSuggestion(0)
    setShowSuggestions(true)
  }

  const handleClick = useCallback(
    (e: MouseEvent<HTMLLIElement>) => {
      setActiveSuggestion(0)
      setShowSuggestions(false)
      setUserInput(e.currentTarget.innerText)
      onSearch(e.currentTarget.innerText)
    },
    [onSearch]
  )

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "Enter":
        // Commit suggested value
        if (showSuggestions && filteredSuggestions.length > 0) {
          const query = filteredSuggestions[activeSuggestion].value
          e.preventDefault()
          setUserInput(query)
          setShowSuggestions(false)
          setActiveSuggestion(0)
          onSearch(query)
        } else {
          if (userInput) {
            inputRef.current?.blur()
            onSearch(userInput)
          }
        }
        break

      case "ArrowUp":
        // Select previous suggestion
        if (showSuggestions) {
          e.preventDefault()
          setActiveSuggestion((idx) => (idx > 0 ? idx - 1 : idx))
        }
        break

      case "ArrowDown":
        // Show / Select next suggestion
        e.preventDefault()
        if (!showSuggestions) {
          setShowSuggestions(true)
          setActiveSuggestion(0)
        } else {
          setActiveSuggestion((idx) =>
            idx < filteredSuggestions.length - 1 ? idx + 1 : idx
          )
        }
        break

      case "Escape":
        setShowSuggestions(false)
        break

      default:
        break
    }
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    onSearch(userInput)
  }

  const suggestionsList = useMemo(() => {
    if (!showSuggestions || !userInput || filteredSuggestions.length === 0) {
      return null
    }
    return (
      <ul className={classes.Suggestions}>
        {filteredSuggestions.map((suggestion, index) => (
          <li
            className={index === activeSuggestion ? classes.active : undefined}
            key={suggestion.value}
            onClick={handleClick}
          >
            {suggestion.value}
          </li>
        ))}
      </ul>
    )
  }, [
    activeSuggestion,
    filteredSuggestions,
    handleClick,
    showSuggestions,
    userInput,
  ])

  useEffect(() => {
    if (defaultValue && typeof defaultValue == "string") {
      setUserInput(defaultValue)
    }
  }, [defaultValue])

  return (
    <div className={classes.Autocomplete}>
      <input
        {...inputProps}
        type="search"
        value={userInput}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onSubmit={handleSubmit}
        ref={inputRef}
      />
      {suggestionsList}
    </div>
  )
}
