import React from "react"

import type { File } from "../../api/schema"
import { avatarAsset } from "../../api/files"

import classes from "./Avatar.module.css"
import { composeClasses } from "../../util/composeClasses"
import { Image } from "../Image"

// import underModerationAvatar from "./under_moderation.jpg"
// import underModerationAvatar2x from "./under_moderation@2x.jpg"

export interface AvatarProps {
  badge?: string | null
  badgeLink?: URL
  image?: File | null
  name: string
  large?: boolean
  underModeration?: boolean
}

export const Avatar: React.FC<AvatarProps> = ({
  badge,
  badgeLink,
  image,
  name = "",
  large = false,
  underModeration = false,
}) => {
  const img = underModeration ? null : image ? avatarAsset(image, name) : null

  return (
    <div
      className={composeClasses(
        classes.avatarContainer,
        badge && classes.withBadge
      )}
    >
      {img ? (
        <Image
          className={composeClasses(
            classes.avatar,
            classes.circle,
            large && classes.large
          )}
          {...img}
        />
      ) : (
        <AvatarPlaceholder large={large} name={name} />
      )}
      {badge ? (
        <span className={classes.badge}>
          {badgeLink !== undefined ? (
            <a
              href={badgeLink.href}
              target="_blank"
              rel="noopener noreferrer external"
            >
              {badge}
            </a>
          ) : (
            badge
          )}
        </span>
      ) : null}
    </div>
  )
}

// const moderationImage = (alt: string): ImageModel => {
//   return {
//     src: underModerationAvatar,
//     srcSet: `${underModerationAvatar}, ${underModerationAvatar2x} 2x`,
//     alt,
//   }
// }

const AvatarPlaceholder = ({
  name,
  large,
}: {
  name: string
  large?: boolean
}) => (
  <div
    className={composeClasses(classes.circle, large && classes.large)}
    role="img"
  >
    <div className={classes.initials}>{initials(name)}</div>
  </div>
)

/**
 * Simple extraction of initials from a name. Non-alphanumerical characters
 * are removed and the name is split by spaces.
 *
 * @param name Full name
 */
const initials = (name: string) => {
  const clean = name.replace(/[^0-9a-z ]/gi, "").trim()
  if (clean.length === 0) {
    return ""
  }
  const components = clean.split(" ")
  if (components.length === 1) {
    return components[0].slice(0, 1)
  }
  return components
    .slice(0, 2)
    .map((c) => c.substring(0, 1))
    .join("")
}
