import React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { addItem } from "../cart/cartSlice"
import { AppState, useAppDispatch } from "../../store"
import { Book } from "../../api/schema"
import { thumbAsset } from "../../api/files"

import classes from "./BookOrderButton.module.css"
import { Button } from "../../components"
import { currency } from "../../util/formatter"

const BookOrderButton: React.FC<{
  book: Book
  className?: string
  showPrice?: boolean
}> = ({ book, className, showPrice = false }) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const isInCart = useSelector(
    (state: AppState) =>
      state.cart.items.find((item) => item.book === book.id) !== undefined
  )

  switch (book.shop_state) {
    case undefined:
      console.warn(
        "Can't produce order button due to missing `shop_state` information",
        book
      )
      return null
    case "sold_out":
      return <span className={classes.soldOutLabel}>Uitverkocht</span>
    case "not_for_sale":
      return null
    case "in_stock":
      return (
        <React.Fragment>
          {showPrice && book.price !== null ? (
            <span className={classes.price}>{currency.format(book.price)}</span>
          ) : null}

          {book.price !== null && book.weight !== null ? (
            <Button
              className={className}
              onClick={() => {
                if (isInCart) {
                  history.push("/winkel/mandje")
                } else {
                  dispatch(
                    addItem({
                      id: book.id,
                      slug: book.slug,
                      title: book.title,
                      price: book.price!,
                      vat: book.vat,
                      weight: book.weight!,
                      thumb:
                        book.cover_image && typeof book.cover_image != "string"
                          ? thumbAsset(book.cover_image)
                          : undefined,
                    })
                  )
                }
              }}
            >
              {isInCart ? "✓ In mandje" : "Bestel"}
            </Button>
          ) : null}
        </React.Fragment>
      )
  }
}

export default BookOrderButton
