import React, { useEffect } from "react"
import { useSelector } from "react-redux"

import {
  Credentials,
  dismissReset,
  requestReset,
  updateCredentials,
} from "./userSlice"
import { AppState, useAppDispatch } from "../../store"

import { Form } from "./Form"

const RequestResetForm: React.FC = () => {
  const dispatch = useAppDispatch()
  const { protocol, host } = document.location
  const resetUrl = `${protocol}//${host}${process.env.PUBLIC_URL}/reset-password`

  const { credentials, didRequestReset, error, isLoading, lastRequestId } =
    useSelector((state: AppState) => state.user)

  const handleCancel = () => {
    dispatch(dismissReset())
  }

  const handleSubmit = ({ email }: Credentials) => {
    dispatch(requestReset({ email, resetUrl }))
  }

  useEffect(() => {
    if (didRequestReset) {
      dispatch(dismissReset())
    }
  }, [didRequestReset, dispatch])

  return (
    <Form
      value={credentials}
      setValue={({ email }) => {
        dispatch(
          updateCredentials({
            email,
            password: "",
          })
        )
      }}
      header="Nieuw wachtwoord"
      hint="Vul je e-mailadres in om een link te ontvangen waarmee je je wachtwoord kunt resetten."
      fields={[
        {
          name: "email",
          type: "email",
          placeholder: "E-mailadres",
          autoComplete: "username",
          required: true,
        },
      ]}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      submitLabel="Stuur reset-link"
      cancelLabel="Terug"
      lastRequestId={lastRequestId}
      error={error}
      isLoading={isLoading}
    />
  )
}

export default RequestResetForm
