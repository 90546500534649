import React, { useState, useCallback } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

import { resetPassword } from "./userSlice"
import { AppState, useAppDispatch } from "../../store"

import { Form } from "./Form"
import RequestResetForm from "./RequestResetForm"
import { Notice } from "../../components"

const ResetForm = () => {
  const dispatch = useAppDispatch()

  const [data, setData] = useState({ password: "" })

  const { user, isLoading, error, lastRequestId, didReset } = useSelector(
    (state: AppState) => state.user
  )

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const token = params.get("token") ?? ""

  const handleSubmit = useCallback(
    ({ password }: { password: string }) => {
      dispatch(resetPassword({ password, token }))
    },
    [dispatch, token]
  )

  if (!token) {
    return <RequestResetForm />
  }

  if (user) {
    return (
      <Notice>
        Je bent ingelogd als <strong>{user.display_name}</strong>.
      </Notice>
    )
  }

  if (didReset) {
    return (
      <Notice>
        Je wachtwoord is aangepast. Je kunt nu inloggen met je e-mailadres en je
        nieuwe wachtwoord.
      </Notice>
    )
  }

  return (
    <Form
      header="Nieuw wachtwoord"
      hint="Vul een nieuw wachtwoord in."
      value={data}
      setValue={setData}
      fields={[
        {
          name: "password",
          type: "password",
          placeholder: "Nieuw wachtwoord",
          required: true,
          autoComplete: "new-password",
        },
      ]}
      isLoading={isLoading}
      error={error}
      lastRequestId={lastRequestId}
      onSubmit={handleSubmit}
    />
  )
}

export default ResetForm
