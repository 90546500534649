import React, { useMemo } from "react"
import { Link } from "react-router-dom"

import { CartItem } from "./types/CartItem"
import { encodeNotes } from "./types/order"

import classes from "./OrderItem.module.css"
import { Button, Image, bookPlaceholder } from "../../components"
import { currency } from "../../util/formatter"

const MAX_METADATA_SIZE = 1_024 // 1 kB

const byteSize = (str: string) => new Blob([str]).size

const encodedSize = (notes: string[]) => byteSize(encodeNotes(notes))

type OrderItemProps = {
  item: CartItem
  editingNotes?: boolean
  onChangeEditingState: (editing: boolean) => void
  onChangeNote: (index: number, note: string) => void
  onChangeQuantity: (quantity: number) => void
  onDelete: () => void
}

const OrderItem: React.FC<OrderItemProps> = ({
  item,
  editingNotes = false,
  ...props
}) => {
  const handleChangeQuantity = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const quantity = parseInt(event.target.value)
    props.onChangeQuantity(quantity)
  }

  const handleChangeNote = (index: number, note: string) => {
    props.onChangeNote(index, note)
  }

  const infoText =
    item.quantity === 1
      ? "Ype signeert graag je boek. Je kunt hier aangeven aan wie je wil dat het wordt opgedragen."
      : "Ype signeert graag je boeken. Je kunt hier per boek aangeven aan wie je wil dat het wordt opgedragen."

  const nonEmptyNotes = useMemo(
    () => item.notes.slice().filter((n) => n.length > 0),
    [item.notes]
  )
  const notesCount = nonEmptyNotes.length

  const estimatedAvailableChars = useMemo(
    () => Math.floor(MAX_METADATA_SIZE - encodedSize(nonEmptyNotes)),
    [nonEmptyNotes]
  )

  return (
    <div className={classes.OrderItem}>
      <div className={classes.imageWrapper}>
        <Link to={`/winkel/${item.slug}`}>
          <Image
            className={classes.thumb}
            {...(item.thumb ?? bookPlaceholder(item.title))}
          />
        </Link>
      </div>
      <div className={classes.details}>
        <div className={classes.itemDescription}>
          <h2>
            <Link to={`/winkel/${item.slug}`}>{item.title}</Link>
          </h2>
          {editingNotes ? (
            <NotesForm
              availableCharacterCount={estimatedAvailableChars}
              onChangeNote={handleChangeNote}
              onSubmit={() => props.onChangeEditingState(false)}
              notes={item.notes}
              text={infoText}
            />
          ) : (
            <NotesTool
              count={notesCount}
              help={infoText}
              total={item.notes.length}
              onClickEdit={() => props.onChangeEditingState(true)}
            />
          )}
        </div>
        <div className={classes.quantityDetails}>
          <select
            name={`item-${item.book}-quantity`}
            value={item.quantity}
            onChange={handleChangeQuantity}
          >
            {[...Array(10)].map((_, i) => (
              <option key={i} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.priceDetails}>
          {currency.format(item.quantity * item.price)}
          <br />
          <Button variant="link" onClick={props.onDelete}>
            Verwijderen
          </Button>
        </div>
      </div>
    </div>
  )
}

const NotesTool: React.FC<{
  count: number
  total: number
  onClickEdit: () => void
  help?: string
}> = ({ count, total, onClickEdit, help }) => {
  const hasNotes = count > 0
  return (
    <span>
      {hasNotes
        ? `✓ ${count} ` + (count === 1 ? "opdracht" : "opdrachten") + " "
        : null}
      <Button variant="link" onClick={onClickEdit} title={help}>
        {hasNotes
          ? "Bewerk"
          : `Voeg ${total === 1 ? "opdracht" : "opdrachten"} toe`}
      </Button>
    </span>
  )
}

const NotesForm: React.FC<{
  availableCharacterCount: number
  onChangeNote: (index: number, note: string) => void
  onSubmit: () => void
  notes: string[]
  text: string
}> = ({ availableCharacterCount, onChangeNote, onSubmit, notes, text }) => {
  const firstEmptyNoteIndex = notes.findIndex((n) => !n)

  const fields = notes.map((note, index) => (
    <input
      key={index}
      name={`note-${index + 1}`}
      className={classes.noteInput + " " + classes.singleInput}
      defaultValue={note}
      onBlur={(event) => {
        if (event.target.value !== note) onChangeNote(index, event.target.value)
      }}
      autoFocus={index === firstEmptyNoteIndex}
      maxLength={note.length + availableCharacterCount}
      placeholder="Opdracht"
    />
  ))

  return (
    <form
      className={classes.notesEditor}
      onSubmit={(event) => {
        event.preventDefault()
        // Manually trigger blur to update the note when user presses enter inside the text input to submit the form:
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur()
        }
        onSubmit()
      }}
    >
      <p className="caption">{text}</p>

      {availableCharacterCount < 100 && (
        <p style={{ color: "var(--error-color)" }}>
          Nog maar {Math.max(0, availableCharacterCount)} tekens!
        </p>
      )}

      {fields.length > 1 ? (
        <ol>
          {fields.map((f, index) => (
            <li key={index}>{f}</li>
          ))}
        </ol>
      ) : (
        fields
      )}

      <Button type="submit" compact>
        Klaar
      </Button>
    </form>
  )
}

export default OrderItem
