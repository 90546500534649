import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { getBanners } from "../../api/rest"
import { BannersContent as Banner } from "../../api/schema"

type BannersState = {
  banners: Banner[] | null
  error?: string
  isLoading: boolean
}

const initialState: BannersState = {
  banners: null,
  isLoading: false,
}

export const fetchBanners = createAsyncThunk("banners/get", getBanners)

const bannersSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanners.pending, (state) => {
        state.isLoading = true
        state.error = undefined
      })
      .addCase(fetchBanners.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = undefined
        state.banners = action.payload.slice(0, 2)
      })
      .addCase(fetchBanners.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message
        if (state.banners === null) {
          state.banners = []
        }
      })
  },
})

export default bannersSlice.reducer
