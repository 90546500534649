import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { removeItem, updateNote, updateQuantity } from "./cartSlice"
import { AppState, useAppDispatch } from "../../store"
import { CartItem } from "./types/CartItem"

import classes from "./Cart.module.css"
import OrderConfirmation from "./OrderConfirmation"
import OrderItem from "./OrderItem"
import OrderSummary from "./OrderSummary"
import { Button, Container } from "../../components"
import { Helmet } from "react-helmet-async"

const Cart = () => {
  const history = useHistory()
  const { items, hasPendingOrder } = useSelector((state: AppState) => ({
    hasPendingOrder: state.cart.order?.status === "created",
    items: state.cart.items,
  }))

  if (hasPendingOrder) {
    return <OrderConfirmation />
  }

  const isEmpty = items.length === 0

  return (
    <Container readableWidth>
      <Helmet>
        <title>Je bestelling</title>
      </Helmet>
      <section>
        <h1>Je bestelling</h1>
        {isEmpty && <p>Het mandje is leeg.</p>}
      </section>

      {!isEmpty ? (
        <React.Fragment>
          <section>
            <OrderItemsList items={items} />
          </section>
          <section>
            <OrderSummary countryEditable />
          </section>
        </React.Fragment>
      ) : null}

      <section className={classes.buttonsRow}>
        <Button variant={isEmpty ? "primary" : "secondary"} linkTo="/winkel">
          Verder winkelen
        </Button>
        {!isEmpty ? (
          <Button
            onClick={() => history.push("/winkel/checkout")}
            disabled={isEmpty}
          >
            Naar verzending
          </Button>
        ) : null}
      </section>
    </Container>
  )
}

const OrderItemsList = ({ items }: { items: CartItem[] }) => {
  const emptyNoteIdx = items.findIndex(({ notes }) => !notes.join("").length)
  const [notesIndex, selectNotesIndex] = useState(emptyNoteIdx)
  const dispatch = useAppDispatch()

  return (
    <ol className={classes.orderItems}>
      {items.map((item, index) => (
        <li key={item.book}>
          <OrderItem
            item={item}
            editingNotes={index === notesIndex}
            onChangeEditingState={(editing) =>
              selectNotesIndex(editing ? index : -1)
            }
            onChangeNote={(index, note) =>
              dispatch(updateNote({ book: item.book, index, note }))
            }
            onChangeQuantity={(newQuantity) =>
              dispatch(updateQuantity({ ...item, quantity: newQuantity }))
            }
            onDelete={() => dispatch(removeItem(item))}
          />
        </li>
      ))}
    </ol>
  )
}

export default Cart
