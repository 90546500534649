import { useEffect } from "react"

export const MatomoTag = ({ children, url }) => {
  useEffect(() => {
    if (!url) {
      return
    }

    // Matomo Tag Manager
    var _mtm = (window._mtm = window._mtm || [])
    _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" })
    ;(function () {
      var d = document,
        g = d.createElement("script"),
        s = d.getElementsByTagName("script")[0]
      g.async = true
      g.src = url
      s.parentNode.insertBefore(g, s)
    })()
  }, [url])

  return children
}
