import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { getInfo } from "../../api/rest"
import { InfoPage } from "../../api/schema"
import { AppState } from "../../store"

interface AboutState {
  isLoading: boolean
  error: null | string
  data: null | InfoPage
}

export const fetchAbout = createAsyncThunk("about/fetch", getInfo)

const aboutSlice = createSlice({
  name: "about",
  initialState: {
    isLoading: false,
    error: null,
    data: null,
  } as AboutState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAbout.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchAbout.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.data = action.payload
      })
      .addCase(fetchAbout.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? null
      })
  },
})

export const selectInfo = (state: AppState) => state.about

export default aboutSlice.reducer
