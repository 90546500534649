import React, { useEffect } from "react"
import { useSelector } from "react-redux"

import { AppState, useAppDispatch } from "../../store"

import classes from "./Banners.module.css"
import { Banner, BookBanner } from "../../components"
import { fetchBanners } from "./bannersSlice"
import { BannersContent, Book, GenericBanner } from "../../api/schema"
import { useParams } from "react-router-dom"
import StripContainer from "../../components/StripContainer"

const BannerElement = ({ content }: { content: BannersContent }) => {
  const { collection, header, id, item } = content

  switch (collection) {
    case "books":
      return (
        <BookBanner
          key={id}
          className={classes.banner}
          header={header}
          book={item as Book}
        />
      )
    case "generic_banners":
    default:
      return (
        <Banner
          key={id}
          className={classes.banner}
          header={header}
          banner={item as GenericBanner}
        />
      )
  }
}

interface BannersProps {
  id?: number
}

const Banners: React.FC<BannersProps> = ({ id }) => {
  const dispatch = useAppDispatch()
  const { banners, isLoading } = useSelector((state: AppState) => state.banners)
  const didLoad = banners !== null
  const selectedBanner = banners?.find((b) => b.id === id)

  useEffect(() => {
    if (!didLoad && !isLoading) {
      dispatch(fetchBanners())
    }
  }, [didLoad, dispatch, isLoading])

  if (!banners?.length) {
    return null
  }

  const RandomBanner = () => {
    const index = Math.floor(Math.random() * banners.length)
    return <BannerElement content={banners[index]} />
  }

  return (
    <div className={classes.banners}>
      {selectedBanner ? (
        <BannerElement content={selectedBanner} />
      ) : (
        <RandomBanner />
      )}
    </div>
  )
}

export const BannerPreview = () => {
  const { id } = useParams<{ id: string }>()
  return (
    <div style={{ margin: "50px 0" }}>
      <StripContainer>
        <Banners id={+id} />
      </StripContainer>
    </div>
  )
}

export default Banners
