import React from "react"

import classes from "./FavoriteButton.module.css"
import { HeartIcon, HeartIconOutline } from "./icons"

interface FavoriteButtonProps {
  isFavorited: boolean
  likesCount: number
  likesNames?: string[]
  onClick: (didFavorite: boolean) => void
}

export const FavoriteButton: React.FC<FavoriteButtonProps> = ({
  isFavorited,
  likesCount,
  likesNames,
  onClick,
}) => {
  const classNames = [classes.FavoriteButton]
  if (isFavorited) {
    classNames.push(classes.favorited)
  }

  const handleClick = () => {
    onClick(!isFavorited)
  }

  return (
    <div className={classNames.join(" ")}>
      <button
        onClick={handleClick}
        aria-label={isFavorited ? "Niet meer leuk vinden" : "Leuk vinden"}
      >
        {isFavorited ? <HeartIcon /> : <HeartIconOutline />}
      </button>
      {likesCount > 0 && (
        <span
          className={classes.badge}
          title={
            likesNames?.length
              ? `Leuk gevonden door ${likesNames.join(", ")}`
              : undefined
          }
        >
          {likesCount}
        </span>
      )}
    </div>
  )
}
