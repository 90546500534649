import React, { useEffect, useCallback, useRef } from "react"

import { FastForwardIcon, NextIcon, PreviousIcon, RewindIcon } from "./icons"
import classes from "./StripControls.module.css"

const ARROW_LEFT_KEY_CODE = 37
const ARROW_RIGHT_KEY_CODE = 39
const DIRECTION_PREVIOUS = "previous"
const DIRECTION_NEXT = "next"
const KEYDOWN_EVENT = "keydown"

type ArrowButtonProps = {
  onClick?: () => void
  direction: string
  double?: boolean
}

const ArrowButton = React.forwardRef<HTMLButtonElement, ArrowButtonProps>(
  ({ direction, double = false, onClick }, ref) => {
    const icon =
      direction === DIRECTION_PREVIOUS ? (
        double ? (
          <RewindIcon />
        ) : (
          <PreviousIcon />
        )
      ) : double ? (
        <FastForwardIcon />
      ) : (
        <NextIcon />
      )

    return (
      <button
        onClick={onClick}
        className={classes.navButton}
        ref={ref}
        disabled={onClick === undefined}
      >
        {icon}
      </button>
    )
  }
)

const isInViewport = (element: Element) => {
  const rect = element.getBoundingClientRect()
  return (
    rect.bottom > 0 &&
    rect.right > 0 &&
    rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
    rect.left < (window.innerWidth || document.documentElement.clientWidth)
  )
}

type StripControlsProps = {
  /** Called when the previous button is clicked or the left arrow key is pressed. */
  onPrevious?: () => void

  /** Called when the next button is clicked or the right arrow key is pressed. */
  onNext?: () => void

  /** Flag to use an alternate back icon. */
  jumpsBack?: boolean

  /** Flag to use an alternate forward icon. */
  jumpsForward?: boolean
}

const StripControls: React.FC<StripControlsProps> = ({
  onPrevious,
  onNext,
  jumpsBack = false,
  jumpsForward = false,
}) => {
  const controlsRef = useRef<HTMLDivElement>(null)
  const nextButtonRef = useRef<HTMLButtonElement>(null)
  const prevButtonRef = useRef<HTMLButtonElement>(null)

  const flashButton = (element: HTMLButtonElement) => {
    element.classList.toggle(classes.active, true)
    setTimeout(() => {
      element.classList.toggle(classes.active, false)
    }, 200)
  }

  const handleKeyDown = useCallback(
    (event: any) => {
      /** Don't navigate when nav buttons are out of view
       * or if target is another interactive element,
       * such as an input or button. */
      if (
        !controlsRef.current ||
        !isInViewport(controlsRef.current) ||
        event.target.nodeName === "INPUT" ||
        event.target.nodeName === "TEXTAREA"
      ) {
        return
      }
      if (onPrevious !== undefined && event.keyCode === ARROW_LEFT_KEY_CODE) {
        event.preventDefault()
        event.stopPropagation()
        if (prevButtonRef.current) {
          flashButton(prevButtonRef.current)
        }
        onPrevious()
      }
      if (onNext !== undefined && event.keyCode === ARROW_RIGHT_KEY_CODE) {
        event.preventDefault()
        event.stopPropagation()
        if (nextButtonRef.current) {
          flashButton(nextButtonRef.current)
        }
        onNext()
      }
    },
    [onPrevious, onNext]
  )

  useEffect(() => {
    document.addEventListener(KEYDOWN_EVENT, handleKeyDown)
    return () => {
      document.removeEventListener(KEYDOWN_EVENT, handleKeyDown)
    }
  }, [handleKeyDown])

  return (
    <div className={classes.StripControls} ref={controlsRef}>
      <ArrowButton
        onClick={onPrevious}
        double={jumpsBack}
        direction={DIRECTION_PREVIOUS}
        ref={prevButtonRef}
      />
      <ArrowButton
        onClick={onNext}
        double={jumpsForward}
        direction={DIRECTION_NEXT}
        ref={nextButtonRef}
      />
    </div>
  )
}

export default StripControls
