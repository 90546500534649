import React from "react"

import { useAppDispatch } from "../../store"
import { showLogin } from "../user/userSlice"

import classes from "../../components/Strip.module.css"
import { Button } from "../../components"
import { Link } from "react-router-dom"

const PreviewPanel = () => {
  const dispatch = useAppDispatch()
  const handleLogin = (event: React.MouseEvent) => {
    event.preventDefault()
    dispatch(showLogin())
  }
  return (
    <div className={`${classes.panel} ${classes.previewCTA}`}>
      <div className={classes.content}>
        {/* TODO: add preview illustration? */}
        <Button onClick={handleLogin}>Log in</Button>
        <p>
          of <Link to="/register">maak een gratis account</Link> om deze strip
          te lezen.
        </p>
      </div>
    </div>
  )
}

export default PreviewPanel
