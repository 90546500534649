import React from "react"
import { Helmet } from "react-helmet-async"
import ReactMarkdown from "react-markdown"

import useInfo from "./useInfo"
import { Image as ImageModel, InfoPage } from "../../api/schema"

import classes from "./About.module.css"
import { Image, Container, Spinner, EmptyMessage } from "../../components"
import { asset } from "../../api/files"

const About = () => {
  const { data, isLoading, error } = useInfo()

  return (
    <React.Fragment>
      {data && (
        <Helmet>
          <title>{data.header}</title>
        </Helmet>
      )}
      {isLoading ? (
        <Spinner centered />
      ) : error ? (
        <section>{error}</section>
      ) : !data ? (
        <EmptyMessage>Er ging iets mis...</EmptyMessage>
      ) : (
        <AboutComponent data={data} />
      )}
    </React.Fragment>
  )
}

type AboutProps = {
  data: InfoPage
}

const AboutComponent = ({ data }: AboutProps) => {
  const { header, body_text, image, contact_header, contact_text } = data

  return (
    <Container className={classes.About}>
      <section>
        <div className={classes.main}>
          {image && typeof image == "object" && (
            <ImageWithByline image={asset(image)} />
          )}
          <div className={classes.text}>
            <h1>{header}</h1>
            <ReactMarkdown>{body_text}</ReactMarkdown>
          </div>
        </div>
      </section>
      <hr />
      <section className={classes.contact}>
        <h2>{contact_header}</h2>
        <ReactMarkdown className={classes.text}>{contact_text}</ReactMarkdown>
      </section>
    </Container>
  )
}

const ImageWithByline: React.FC<{ image: ImageModel }> = ({ image }) => (
  <div className={classes.imageWithByline}>
    <Image {...image} />
    {image.description && <p className={classes.byline}>{image.description}</p>}
  </div>
)

export default About
