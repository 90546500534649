import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import { fetchAll, selectOtherSeries } from "./seriesSlice"
import useInfo from "../about/useInfo"
import { Series } from "../../api/schema"
import { useAppDispatch } from "../../store"

import classes from "./SeriesGallery.module.css"
import {
  EmptyMessage,
  Image,
  Notice,
  PageHeader,
  Spinner,
} from "../../components"
import placeholderImage from "./placeholder-series.png"
import placeholderImage2 from "./placeholder-series@2x.png"
import { asset } from "../../api/files"

const SeriesGallery = () => {
  const dispatch = useAppDispatch()
  const { allSeries, isLoading, error } = useSelector(selectOtherSeries)
  const isEmpty = allSeries.length === 0

  useEffect(() => {
    dispatch(fetchAll())
  }, [dispatch])

  const { data: info } = useInfo()

  return (
    <React.Fragment>
      <Helmet>
        <title>Series</title>
      </Helmet>
      {isLoading && isEmpty ? (
        <Spinner centered />
      ) : error ? (
        <Notice error>{error}</Notice>
      ) : (
        <div>
          <PageHeader title="Series" introduction={info?.series_intro} />
          {isEmpty ? (
            <EmptyMessage>Geen series gevonden.</EmptyMessage>
          ) : (
            <Gallery seriesList={allSeries} />
          )}
        </div>
      )}
    </React.Fragment>
  )
}

const Gallery = ({ seriesList }: { seriesList: Series[] }) => (
  <div className={classes.SeriesGallery}>
    {seriesList.map((s) => (
      <SeriesCard key={s.slug} series={s} />
    ))}
  </div>
)

const SeriesCard = ({ series }: { series: Series }) => {
  const { payoff, slug, title, cover_image } = series
  return (
    <Link className={classes.SeriesCard} to={`/series/${slug}`}>
      <Image
        {...(cover_image && typeof cover_image == "object"
          ? asset(cover_image, series.title)
          : placeholder(series))}
      />
      <h2>{title}</h2>
      {payoff && <p>{payoff}</p>}
    </Link>
  )
}

const placeholder = (series: Series) => ({
  src: placeholderImage,
  srcSet: `${placeholderImage}, ${placeholderImage2} 2x`,
  alt: series.title,
})

export default SeriesGallery
