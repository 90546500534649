import React from "react"
import {
  FaXTwitter as TwitterIcon,
  FaInstagram as InstagramIcon,
  FaFacebook as FacebookIcon,
} from "react-icons/fa6"

import classes from "./SocialLinks.module.css"

type LinkIcon = {
  name: string
  url: string
  element: JSX.Element
}

/**
 * Using icons from the FontAwesome collection in react-icons: https://react-icons.netlify.com/#/icons/fa
 * With colors defined on: https://simpleicons.org
 */
const links: LinkIcon[] = [
  {
    name: "Facebook",
    url: "https://facebook.com/fotostrips",
    element: <FacebookIcon style={{ color: "#1877F2" }} />,
  },
  {
    name: "Instagram",
    url: "https://instagram.com/fotostrips.nl",
    element: <InstagramIcon style={{ color: "#E4405F" }} />,
  },
  {
    name: "Twitter",
    url: "https://twitter.com/fotostrips",
    element: <TwitterIcon style={{ color: "#000000" }} />,
  },
]

export const SocialLinks = () => (
  <ul className={classes.social}>
    {links.map(({ name, url, element }) => (
      <li key={name}>
        <a
          href={url}
          rel="external noopener noreferrer"
          target="_blank"
          title={name}
        >
          {element}
        </a>
      </li>
    ))}
  </ul>
)
