import React, { useEffect, useRef, RefObject } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { useLocation } from "react-router-dom"

import { AppState } from "../../store"
import { Strip } from "../../api/schema"

import classes from "./FeedGallery.module.css"
import { ThumbLink } from "../../components"
import { composeClasses } from "../../util/composeClasses"
import { formatPublishDay } from "../../util/moment"

type FeedGalleryProps = {
  /** Placement of the gallery. Options are `header` (default) or `footer`. */
  variant?: string
}

/**
 * Component that reads and displays the most recent
 * feed strips from the store.
 */
const FeedGallery = ({ variant = "header" }: FeedGalleryProps) => {
  const { pathname } = useLocation()

  // Get feed strips
  const strips = useSelector(
    (state: AppState) => state.feed.strips,
    shallowEqual
  )
  const firstID = strips.length ? strips[0].id : undefined

  const scrollingRef = useRef<HTMLDivElement>(null)
  const refs = strips.reduce((result, strip) => {
    result[strip.id] = React.createRef()
    return result
  }, {} as { [key: number]: RefObject<HTMLLIElement> })

  // Find currently opened strip's ID for marking as active
  const currentID = useSelector((state: AppState) =>
    state.strip.index !== undefined
      ? state.strip.strips[state.strip.index]?.id
      : undefined
  )

  // Scroll gallery
  useEffect(() => {
    if (!pathname.startsWith("/strip")) {
      return
    }
    if (currentID === undefined) {
      // On home page, scroll to start.
      scrollingRef.current?.scrollTo({
        left: 0,
        behavior: "smooth",
      })
    } else {
      // Scroll current strip's thumb into view if it's featured in this gallery.
      const ref = refs[currentID]
      if (!ref?.current) {
        return
      }
      const minX = ref.current.offsetLeft
      const maxX = minX + ref.current.clientWidth
      const viewPortWidth = scrollingRef.current!.offsetWidth
      if (minX < scrollingRef.current!.scrollLeft) {
        scrollingRef.current?.scrollTo({
          left: minX - 30,
          behavior: "smooth",
        })
      } else if (maxX > scrollingRef.current!.scrollLeft + viewPortWidth) {
        scrollingRef.current?.scrollTo({
          left: maxX + 30 - viewPortWidth,
          behavior: "smooth",
        })
      }
    }
  }, [firstID, currentID, refs, pathname])

  const randomSection =
    /* {strips.length ? (
          <div className={classes.section}>
            {variant === "footer" ? (
              <h3>
                Uit het <Link to="/ype">archief</Link>
              </h3>
            ) : null}
            <ul>
              <li key={"random-" + strips[0].id}>
                <FeedItem strip={strips[0]]} 
              </li>
            </ul>
          </div>
        ) : null} */
    null

  const recentSection = strips.length > 0 && (
    <div className={classes.section}>
      {variant === "footer" ? <h3>Recent</h3> : null}
      <ul>
        {strips.map((strip) => (
          <li key={"recent-" + strip.id} ref={refs[strip.id]}>
            <FeedItem
              strip={strip}
              isActive={
                pathname === "/strip/" + strip.id ||
                (pathname === "/" && strip.id === currentID)
              }
            />
          </li>
        ))}
      </ul>
    </div>
  )

  if (!randomSection && !recentSection) {
    return null
  }

  return (
    <div
      className={composeClasses(
        classes.FeedGallery,
        variant === "footer" ? classes.footer : classes.header
      )}
    >
      <div className={classes.scrollable} ref={scrollingRef}>
        {randomSection}
        {recentSection}
      </div>
    </div>
  )
}

const FeedItem: React.FC<{ strip: Strip; isActive: boolean }> = ({
  strip,
  isActive,
}) => (
  <ThumbLink
    strip={strip}
    subtitle={
      <span title={strip.published_on}>
        {formatPublishDay(strip.published_on)}
      </span>
    }
    isActive={() => isActive}
  />
)

export default FeedGallery
