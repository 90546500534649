import { throttle } from "lodash"
import { createRef, useEffect, useState } from "react"

interface UseVisibilityOptions {
  /** Offset from the client rect's bottom. */
  offset: number

  /** How long to throttle scroll events, in ms. */
  throttle: number
}

export function useVisibility<Element extends HTMLElement>(
  options: UseVisibilityOptions
): [boolean, React.RefObject<Element>] {
  const [isVisible, setIsVisible] = useState(false)
  const elementRef = createRef<Element>()

  useEffect(() => {
    const onScroll = throttle(
      () => {
        if (!elementRef.current) {
          setIsVisible(false)
          return
        }
        const { top, bottom } = elementRef.current.getBoundingClientRect()
        setIsVisible(
          bottom + options.offset >= 0 &&
            top - options.offset < window.innerHeight
        )
      },
      options.throttle,
      { trailing: true }
    )

    onScroll()
    document.addEventListener("scroll", onScroll, true)

    return () => {
      document.removeEventListener("scroll", onScroll, true)
      onScroll.cancel()
    }
  }, [elementRef, options.offset, options.throttle])

  return [isVisible, elementRef]
}
