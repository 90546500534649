import React, { useCallback } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { logout, showLogin, dismissLogin } from "./userSlice"
import { AppState, useAppDispatch } from "../../store"
import { avatarAsset } from "../../api/files"

import LoginForm from "./LoginForm"
import RequestResetForm from "./RequestResetForm"
import { ActionModal, IconMenuButton, IconMenuItem } from "../../components"
import { UserIcon } from "../../components/icons"

const UserMenu = () => {
  const { isLoading, user, showingLoginModal, showingResetModal } = useSelector(
    (state: AppState) => state.user
  )
  const dispatch = useAppDispatch()
  const history = useHistory()

  const icon =
    user?.avatar && typeof user.avatar == "object" ? (
      <img
        src={avatarAsset(user.avatar, user.display_name).src}
        alt={user.display_name}
      />
    ) : (
      <UserIcon />
    )

  const ACCOUNT_PATH = "/account"
  const LOGIN_VALUE = "/login"
  const LOGOUT_VALUE = "/logout"
  const REGISTER_PATH = "/register"

  const handleSelection = useCallback(
    (
      value: any //, event: React.SyntheticEvent<HTMLElement, Event>
    ) => {
      switch (value) {
        case LOGIN_VALUE:
          dispatch(showLogin())
          break
        case LOGOUT_VALUE:
          dispatch(logout())
          break
        default:
          history.push(value)
          break
      }
    },
    [dispatch, history]
  )

  const handleDismiss = () => {
    dispatch(dismissLogin())
  }

  return (
    <li>
      <IconMenuButton
        icon={icon}
        name="Account"
        onSelect={handleSelection}
        title="Account"
      >
        {isLoading
          ? [<IconMenuItem key="loading">Bezig met laden...</IconMenuItem>]
          : user === undefined
          ? [
              <IconMenuItem key={LOGIN_VALUE} value={LOGIN_VALUE}>
                Inloggen
              </IconMenuItem>,
              <IconMenuItem key={REGISTER_PATH} value={REGISTER_PATH}>
                Nieuwe account
              </IconMenuItem>,
            ]
          : [
              <IconMenuItem key={ACCOUNT_PATH} value={ACCOUNT_PATH}>
                Account
              </IconMenuItem>,
              <IconMenuItem key={LOGOUT_VALUE} value={LOGOUT_VALUE}>
                Uitloggen
              </IconMenuItem>,
            ]}
      </IconMenuButton>
      <ActionModal open={showingLoginModal} onDismiss={handleDismiss}>
        {showingResetModal ? (
          <RequestResetForm />
        ) : (
          <LoginForm onCancel={handleDismiss} />
        )}
      </ActionModal>
    </li>
  )
}

export default UserMenu
