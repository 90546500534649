import React, { useState, useMemo, useEffect, useRef, useCallback } from "react"
import { useSelector } from "react-redux"

import { Comment as CommentModel, CommentLike } from "../../api/schema"
import { AppState } from "../../store"

import classes from "./Comments.module.css"
import { Avatar, AvatarProps, Button, FavoriteButton } from "../../components"
import { CommentIcon, VerifiedIcon } from "../../components/icons"
import StripContainer from "../../components/StripContainer"
import { composeClasses } from "../../util/composeClasses"
import { formatPublishDay, formatTime } from "../../util/moment"

const Name = ({ name = "", isVerified = false, title = "" }) => (
  <div className={classes.name} title={title ?? undefined}>
    {name}
    {isVerified && (
      <VerifiedIcon
        className={classes.verifiedIcon}
        title="Bekend van fotostrips"
      />
    )}
  </div>
)

type FooterProps = {
  isLikedByUser: boolean
  likesCount: number
  likesNames?: string[]
  onLike: (didLike: boolean) => void
  onReply?: () => void
  date: string
  isReplying?: boolean
}

const Footer: React.FC<FooterProps> = ({
  isLikedByUser = false,
  likesCount = 0,
  likesNames,
  onLike,
  onReply,
  date,
  isReplying = false,
}) => (
  <div className={classes.footer}>
    <FavoriteButton
      isFavorited={isLikedByUser}
      likesCount={likesCount}
      likesNames={likesNames}
      onClick={onLike}
    />
    {onReply && <ReplyButton onClick={onReply} active={isReplying} />}
    <time className={classes.date} dateTime={date} title={date}>
      {formatTime(date)}
    </time>
  </div>
)

const ReplyButton = ({
  onClick,
  active = false,
}: {
  onClick: () => void
  active?: boolean
}) => {
  return (
    <button
      className={composeClasses(classes.replyButton, active && classes.active)}
      onClick={onClick}
      aria-label="Reageren"
    >
      <CommentIcon />
    </button>
  )
}

export const CommentAvatar = ({
  includeBadgeLink = true,
  ...props
}: AvatarProps & { includeBadgeLink?: boolean }) => (
  <div
    className={classes.avatarContainer}
    title={
      props.badge && includeBadgeLink
        ? `Doe als ${props.name} en steun Fotostrips met een Petje.af lidmaatschap!`
        : undefined
    }
  >
    <Avatar
      {...props}
      badgeLink={
        includeBadgeLink
          ? new URL("https://petjeaf.com/fotostrips")
          : props.badgeLink
      }
    />
    <div className={classes.conversationLine} />
  </div>
)

type CommentProps = {
  commentTree: CommentTree
  isCollapsed: boolean
  commentForm: (id: number, onClose?: () => void) => any
  onReply?: () => void
  onLike: (comment: number) => void
  onUnlike: (like: CommentLike) => void
}

const Comment: React.FC<CommentProps> = ({
  commentTree,
  isCollapsed,
  commentForm,
  onReply,
  onLike,
  onUnlike,
}) => {
  const { comment, replies } = commentTree
  const ref = useRef<HTMLDivElement>(null)
  const [isReplying, setIsReplying] = useState(false)
  const userId = useSelector((state: AppState) =>
    state.user.user ? state.user.user.id : undefined
  )
  const myLike =
    userId && comment.likes
      ? comment.likes.find(
          (like) =>
            like.owner === userId ||
            (typeof like.owner == "object" && like.owner.id === userId)
        )
      : undefined

  const handleLike = (didLike: boolean) => {
    if (didLike) {
      onLike(comment.id)
    } else if (myLike && myLike.id) {
      onUnlike(myLike)
    }
  }

  const toggleForm = () => {
    setIsReplying(!isReplying)
  }

  const closeForm = useCallback(() => {
    setIsReplying(false)
  }, [])

  const className = [
    classes.Comment,
    replies.length > 0 ? classes.withReplies : "",
  ].join(" ")

  // Scroll into view when toggling reply form
  useEffect(() => {
    if (isReplying) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      })
    }
  }, [isReplying])

  const name =
    comment.owner && typeof comment.owner == "object"
      ? comment.owner?.display_name
      : comment.name

  let likesNames: string[] | undefined = []
  comment.likes?.forEach((like) => {
    if (typeof like.owner == "object") {
      likesNames?.push(like.owner.display_name)
    }
  })

  return (
    <div className={className} ref={ref} data-author={name}>
      <div className={classes.body}>
        <CommentAvatar
          badge={
            typeof comment.owner == "object" &&
            comment.owner?.membership &&
            typeof comment.owner.membership == "object" &&
            !comment.owner.hide_membership_badge
              ? comment.owner.membership.badge
              : null
          }
          image={
            typeof comment.owner == "object" &&
            comment.owner?.avatar &&
            typeof comment.owner.avatar == "object"
              ? comment.owner.avatar
              : null
          }
          name={name}
          underModeration={
            comment.owner &&
            typeof comment.owner == "object" &&
            comment.owner.avatar &&
            typeof comment.owner.avatar == "object"
              ? comment.owner.avatar.is_avatar_approved === false
              : undefined
          }
        />

        <div className={classes.content}>
          <Name
            name={name}
            isVerified={
              comment.owner && typeof comment.owner == "object"
                ? comment.owner.verified
                : false
            }
            title={
              comment.owner &&
              typeof comment.owner == "object" &&
              comment.owner.registered_on
                ? `Fotostriplezer sinds ${formatPublishDay(
                    comment.owner.registered_on
                  )}`
                : ""
            }
          />
          <p className={classes.text}>{comment.text}</p>
          <Footer
            isLikedByUser={myLike !== undefined}
            likesCount={comment.likes?.length ?? 0}
            likesNames={likesNames}
            date={comment.created_on}
            onLike={handleLike}
            onReply={onReply || toggleForm}
            isReplying={isReplying}
          />
        </div>
      </div>
      {isReplying || (!isCollapsed && replies && replies.length > 0) ? (
        <div className={classes.replies}>
          {replies.length > 0 && (
            <CommentsList
              comments={replies}
              isCollapsed={isCollapsed}
              commentForm={commentForm}
              onLike={onLike}
              onUnlike={onUnlike}
              onReply={toggleForm}
            />
          )}
          {isReplying && commentForm(comment.id, closeForm)}
        </div>
      ) : null}
    </div>
  )
}

type CommentsListProps = {
  comments: CommentTree[]
  isCollapsed: boolean
  commentForm: (id: number, onClose?: () => void) => any
  onReply?: () => void
  onLike: (comment: number) => void
  onUnlike: (like: CommentLike) => void
}

const CommentsList: React.FC<CommentsListProps> = ({
  comments,
  isCollapsed,
  commentForm,
  onReply,
  onLike,
  onUnlike,
}) => {
  const visibleComments = isCollapsed ? comments.slice(0, 3) : comments

  return (
    <>
      {visibleComments.map((comment) => {
        return (
          <Comment
            key={comment.comment.id}
            commentTree={comment}
            isCollapsed={isCollapsed}
            commentForm={commentForm}
            onReply={onReply}
            onLike={onLike}
            onUnlike={onUnlike}
          />
        )
      })}
    </>
  )
}

type CommentsProps = {
  comments: CommentModel[]
  commentForm: (replyTo?: number, onClose?: () => void) => JSX.Element
  onLike: (comment: number) => void
  onUnlike: (like: CommentLike) => void
}

const Comments: React.FC<CommentsProps> = ({
  comments,
  commentForm,
  onLike,
  onUnlike,
}) => {
  const commentsCount = comments.length
  const canCollapse = false // commentsCount > 5
  const [isCollapsed, setIsCollapsed] = useState(canCollapse)
  const [isCommenting, setIsCommenting] = useState(true)

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  const handleShowForm = () => {
    setIsCommenting(true)
  }

  const tree = useMemo(() => makeTree(comments), [comments])

  return (
    <StripContainer>
      <div className={classes.Comments}>
        <h2>Reacties</h2>
        <CommentsList
          comments={tree}
          isCollapsed={isCollapsed}
          commentForm={commentForm}
          onLike={onLike}
          onUnlike={onUnlike}
        />

        {canCollapse || !isCommenting ? (
          <div className={classes.buttons}>
            {canCollapse && (
              <Button variant="secondary" onClick={toggleCollapse}>
                {isCollapsed ? `${commentsCount} comments` : `Minder comments`}
              </Button>
            )}
            {!isCommenting && (
              <Button onClick={handleShowForm}>
                <CommentIcon /> Reageren
              </Button>
            )}
          </div>
        ) : null}

        {isCommenting && commentForm(undefined, undefined)}
      </div>
    </StripContainer>
  )
}

type CommentTree = {
  comment: CommentModel
  replies: CommentTree[]
}

/**
 * Builds a two-level "tree" from an array of comments: in the resulting array, each top-level comment
 * is given a `replies` array property.
 *
 * @param comments Array of comments whose `reply_to` property either is `undefined` or points to another top-level comment's `id`.
 */
const makeTree = (comments: CommentModel[]): CommentTree[] =>
  comments
    .filter((c) => c.reply_to === null)
    .map((comment) => ({
      comment,
      replies: comments
        .filter((r) => {
          if (r.reply_to === null) return false
          if (typeof r.reply_to == "number") return r.reply_to === comment.id
          return r.reply_to.id === comment.id
        })
        .map((comment) => ({ comment, replies: [] })),
    }))

export default Comments
