import type { DirectusUser } from "@directus/sdk"
import type Schema from "fs23-schema"

import type { Country } from "./Country"

export type CustomerInfo = {
  email?: string
  firstName?: string
  lastName?: string
  address?: string
  postalCode?: string
  city?: string
  country: Country
  saveInfoForUser: boolean
  subscribe: boolean
}

export const mergeCustomerInfoWithUser = (
  current: CustomerInfo,
  user: DirectusUser<Schema>
): CustomerInfo => {
  const storedAddress =
    user?.billing_address && user.billing_address.length
      ? user.billing_address[0]
      : null
  return {
    email: user.email ?? current.email,
    firstName: user.first_name ?? current.firstName,
    lastName: user.last_name ?? current.lastName,
    address: storedAddress?.address ?? current.address,
    postalCode: storedAddress?.postal_code ?? current.postalCode,
    city: storedAddress?.city ?? current.city,
    country: (storedAddress?.country as Country) ?? current.country,
    saveInfoForUser: !!storedAddress || !!user.first_name,
    subscribe: false,
  }
}
